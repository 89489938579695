import { React, useState, useEffect } from 'react'
import axios from "axios";

import { getCookie } from '../ReactCookie';

import '../css/statistics.css';

import { FaPhoneAlt } from "react-icons/fa";
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import Container from 'react-bootstrap/Container'; 
import Chart from "react-apexcharts";

const Statistics = (props) => {

  const chartStyle = {
    maxWidth: 800,
    minWidth:280,
    height: 500,
    margin:"auto"  
  }

  
  let line_number   = getCookie("line_number");
  let corp_idx    = props.cookie.corp_idx;  

  const today = new Date();
  const year = today.getFullYear(); // 년도
  const month = today.getMonth() + 1;  // 월
  let date = today.getDate();  // 날짜
  const day = today.getDay();  // 요일
  if (date < 10) { date = "0" + date; }

  const [nowDate, setNowDate] = useState(year + '-' + month + '-' + date);

  let fromDate = new Date(year, month - 1, date - 7);
  const prevDate = new Date(fromDate.getTime() - (fromDate.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);  
  
  const [value, setValue] = useState([prevDate, nowDate]);
  //const [value, onChange] = useState([prevDate, nowDate]);

  const [categoriesData, setCategoriesData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);


  /*
  return data.data.length > 0 && data.data.map(({ stat }) => {
    return { value: stat, label: stat };
  });
  */
  
  useEffect(() => {
    const fetchData = async () => {

      let from = new Date(value[0]);
      let to = new Date(value[1]);

      from = new Date(from.getTime()-(from.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      to  = new Date(to.getTime()-(to.getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      
      const result = await axios({
          method: 'GET',
          url: `/mgr/records/stat?corp_idx=${corp_idx}&line_number=${line_number}&from=` + from + `&to=`+to,
          headers: {
              //'Authorization': 'Basic xxxxxxxxxxxxxxxxxxx',
              'Content-Type' : 'application/json' 
          },
          //data: params
      });
      
      //setData(result.data);
      
      let cat = result.data.data.map((stat) =>
        //`'${stat.line_date}'`
        stat.line_date
      );
      setCategoriesData(cat);


      let ser = result.data.data.map((stat) =>        
        stat.count
      );
      setSeriesData(ser);            
      
    };
    fetchData();    
  }, [value]);

  
  useEffect(() => {    
    setOptions({
      xaxis: {
        categories: categoriesData
      }
    });
  }, [categoriesData]);
  

  useEffect(() => {    
    setSeries([{
      name: '통화건수',
      data: seriesData
    }])
  }, [seriesData]);
  
    
  //
  const [options, setOptions] = useState({
    chart: {
      id: 'apexchart'
    },
    xaxis: {
      categories: ['']
    }
  })

  //통화건수
  const [series, setSeries] = useState([{
    name: '통화건수',
    data: ['']
  }])
  
  

  return (
    <Container className="mt-5">
      <div className="h3 mb-4"><FaPhoneAlt /> 통계</div>
      
      <div className="text-center">
        <DateRangePicker className="w-100"
                          onChange={setValue}
                          value={value}
        />
        
        <Chart options={options} series={series} type="bar" style={chartStyle} />
        </div>
    </Container>  
    )
}
 
export default Statistics;
