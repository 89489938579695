import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Form from 'react-bootstrap/Form';
import { BsXSquare } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { NullPopup, PwdPopup, ResultPopup } from './Popup';

import '../../css/login.css';
import '../../css/popup.css';
const FindInfo = (props) => {

    const { state } = useLocation();
    
    const [corp_number, setCorp_number] = useState("");
    const [corp_name, setCorp_name] = useState("");
    const [business_number, setBusiness_number] = useState("");
    const [line_number, setLine_number] = useState("");
    const [result, setResult] = useState([]);

    const [title, setTitle] = useState("");
    const [title2, setTitle2] = useState("");
    const [title3, setTitle3] = useState("");
    const [prev, setPrev] = useState("");
    const [next, setLext] = useState("");

    const [error_open, setErr_open] = useState(false);
    const [result_open, setResult_open] = useState(false);
    const [pwd_open, setPwd_open] = useState(false);

    let mode = state.mode;
    //let infoWidth = (document.body.clientWidth / 2) -  232 / 2 + document.body.scrollLeft;
    //let infoHeight = (document.body.clientHeight / 2) - 148 / 2 + document.body.scrollTop;

    const infoHeight = (window.screen.height /2) - 148;
    const infoWidth = (props.clientWidth / 2) - 232 / 2 + document.body.scrollLeft;

    //console.log(document.body.clientWidth, document.body.clientHeight);
    //console.log(window.screen.width, window.screen.height);

    //let infoWidth  = Math.ceil((window.screen.width  - 232  ) /2);
    //let infoHeight = Math.ceil((window.screen.height ) / 2);
    
    let iBottom = document.body.clientHeight;

    useEffect(() => {
        if (mode === "num") {
            setTitle("대표번호");
            setTitle2("아이디");
            setTitle3("비밀번호");
            setPrev("id");
            setLext("pwd");
        } else if (mode === "id") {
            setTitle("아이디");
            setTitle2("대표번호");
            setTitle3("비밀번호");
            setPrev("num");
            setLext("pwd")
        } else {
            setTitle("비밀번호");
            setTitle2("대표번호");
            setTitle3("아이디");
            setPrev("num");
            setLext("id");
        }
        console.log(mode);
        console.log(title + " || " + title2 + " || " + title3);
    },[mode]);

    // useEffect(() => {
    //     setData( {corp_number,line_number,corp_pwd} );
    //     console.log(data);
    // },[result_open,corp_number,line_number,corp_pwd]);

    const handleKeyPress = (e) => {
        e.key === "Enter" ? submit() : <></>;
    }
    const submit = () => {
        let data = {};
        if ( mode === "num" ) {
            if ( ( corp_name !== "" && business_number !== "" ) || ( corp_name !== "" && line_number !== "" ) ) {
                data = { mode, corp_name, business_number, line_number };
                use_axios(data);
                // console.log("1");
            }
            else {
                // console.log("2");
                setErr_open(true);
                return;
            }
        }
        else if ( mode === "id" ) {
            if ( ( corp_name !== "" && corp_number !== "" ) || ( corp_name !== "" && business_number !== "" ) ) {
                data = { mode, corp_number, corp_name, business_number };
                use_axios(data);
                // console.log("3");
            }
            else {
                // console.log("4");
                setErr_open(true);
                return;
            }
        }
    }
    
    const use_axios = (data) => {
        // console.log(data);
        axios.post('/mgr/member/find', data, { headers: { 'Content-Type': 'application/json' } })
        .then(res => {
            // console.log(res.data);
            if(res.data.res_code===200){
                setResult(res.data.auth);
                setResult_open(true);
            }else{
                // console.log(res.data.res_msg);
                setResult("");
                setResult_open(true);
                return;
            }
        }).catch(function (error) {
            setResult("");
            setErr_open(true);
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }else if (error.request) {
                console.log(error.request);
            }else {
                // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                console.log('Error', error.message);
            }
        });
    }

    const [watchfocus, setWatchfocus] = useState(false);
    const inputRef = useRef([]);
    const btnbottom = useRef();
    
    const seeFocus = () => {
        setWatchfocus(true);
    }
    const outFocus = () => {
        setWatchfocus(false);
    }
    

    return (
        <div id="popup" className="popup">
            {/* height: iBottom , */}
            <div className="popup_qna" style={{ zIndex:1, background:'#fff' }}>
                <div className="popup_custom_top">
                    <div className="mt-4 mr-auto">
                        <Link to={ { pathname: '/login' }} >
                            {/* <AiOutlineClose className="popup_delBtn"></AiOutlineClose> */}
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/close_24px.png`} className="popup_delBtn" alt=""/>
                        </Link>
                    </div>
                    <div className="mt-4 mb-3 mr-auto text-left find_title">
                        <span>{title} 찾기</span>
                    </div>  
                    <div className="mt-3 mb-3 mr-auto text-left find_subtitle">
                        { 
                            mode==="pwd" 
                            ? <div><p className="mb-1">비밀번호가 기억나지 않으실 경우,</p><p className="mb-0">비밀번호 초기화를 진행해야 합니다.</p></div>
                                : <div>
                                    <p className="mb-1">많은 정보를 기입 할수록 정확한 정보가 검색됩니다.</p>
                                    <p className="mb-0">최소 2가지 이상의 정보를 입력해주세요.</p>
                                </div>
                        }
                    </div>  
                </div>
                <div className="popup_custom_center">
                    <div className="mb-3 mr-auto text-left" style={{ display: mode==="num" ? "block" : "none" }}>
                        <Form.Label htmlFor="corp_name" className="mb-0">회사명</Form.Label>
                        <Form.Control type="text" id="corp_name" className="form-control input-lg signin mt-2 " placeholder="회사명"  style={{ marginBottom:'16px' }}
                            required
                            value={corp_name}  
                            onChange={(e) => setCorp_name(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e) }
                            ref={el => (inputRef.current[0]=el)}
                            onFocus={seeFocus}
                            onBlur={outFocus} />
                        <Form.Label htmlFor="business_number"  className="mb-0">사업자번호</Form.Label>
                        <Form.Control type="text" id="business_number" className="form-control input-lg signin mt-2 " placeholder="10자리 ( '-' 없이 입력)" style={{ marginBottom:'16px' }}
                            required
                            value={business_number}  
                            onChange={(e) => setBusiness_number(e.target.value)}
                            ref={el => (inputRef.current[1]=el)}
                            onFocus={seeFocus} 
                            onBlur={outFocus} />
                        <Form.Label htmlFor="line_number"  className="mb-0">아이디(회선번호)</Form.Label>
                        <Form.Control type="text" id="line_number" className="form-control input-lg signin mt-2 " placeholder="( '-' 없이 입력)" style={{ marginBottom:'16px' }}
                            required
                            value={line_number}  
                            onChange={(e) => setLine_number(e.target.value)}
                            ref={el => (inputRef.current[2]=el)}
                            onFocus={seeFocus} 
                            onBlur={outFocus} />
                    </div>                 

                    <div className="mb-3 mr-auto text-left" style={{ display: mode==="id" ? "block" : "none" }}>
                        <Form.Label htmlFor="corp_name" className="mb-0">회사명</Form.Label>
                        <Form.Control type="text" id="corp_name" className="form-control input-lg signin mt-2 " placeholder="회사명" style={{ marginBottom:'16px' }}
                            required
                            value={corp_name}  
                            onChange={(e) => setCorp_name(e.target.value)}
                            ref={el => (inputRef.current[3]=el)}
                            onFocus={seeFocus} 
                            onBlur={outFocus} />
                        <Form.Label htmlFor="corp_name" className="mb-0">대표번호</Form.Label>
                        <Form.Control type="text" id="corp_number" className="form-control input-lg signin mt-2 " placeholder="( '-' 없이 입력)" style={{ marginBottom:'16px' }}
                            required
                            value={corp_number}  
                            onChange={(e) => setCorp_number(e.target.value)}
                            onKeyPress={(e) => handleKeyPress(e) }
                            ref={el => (inputRef.current[4]=el)}
                            onFocus={seeFocus} 
                            onBlur={outFocus} />
                        <Form.Label htmlFor="corp_name" className="mb-0">사업자번호</Form.Label>
                        <Form.Control type="text" id="business_number" className="form-control input-lg signin mt-2 " placeholder="10자리 ( '-' 없이 입력)" style={{ marginBottom:'16px' }}
                            required
                            value={business_number}  
                            onChange={(e) => setBusiness_number(e.target.value)}
                            ref={el => (inputRef.current[5]=el)}
                            onFocus={seeFocus} 
                            onBlur={outFocus} />
                    </div>         

                    <div className="mb-3 mr-auto text-left" style={{ display: mode==="pwd" ? "block" : "none" , fontSize:'15px', lineHeight:'18px' }}>
                        {/* <span className="find_pwd" onClick={() => setPwd_open(true)}>고급형 센트릭스 비밀번호 변경하기</span> */}
                        비밀번호 초기화는 고객센터로 문의해주세요. <br/>(<a href='tel:16618290'>1661-8290</a>)
                    </div>
                </div>
                <div className='popup_bottom_wrap' style={{position: 'fixed', bottom: watchfocus ? '-100px' : '0', width:'100%',height: '100px', maxWidth: '500px'}} ref={btnbottom}>
                    <div className="d-flex justify-content-around popup_custom_link" style={{position: 'absolute', top:'0'}}>
                        <Link to={ { pathname: '/find', state: { mode : prev } } } >
                            <span>{title2} 찾기</span>
                        </Link>
                        <Link className="ml-4" to={ { pathname: '/find', state: { mode : next } } } >
                            <span>{title3} 찾기</span>
                        </Link>
                    </div>
                    { 
                        mode==="pwd" 
                        ?  <Link to={ { pathname: '/login' }}><div className='popup_custom_bottom'><span>닫기</span></div></Link>
                        :  <div className='popup_custom_bottom' onClick={ submit }><span>확인</span></div>
                    }
                </div>
            </div>                
            {error_open === true ? ( <NullPopup open={error_open} top={infoHeight} width={infoWidth} close={setErr_open} timeout={1000} ></NullPopup> ) : <> {error_open} </>}
            {pwd_open === true ? ( <PwdPopup open={pwd_open} top={infoHeight} width={infoWidth} close={setPwd_open} timeout={1000} ></PwdPopup> ) : <> {pwd_open} </>}
            {result_open === true ? (<ResultPopup open={result_open} top={infoHeight} width={infoWidth} bottom={iBottom} mode={mode} title={title} result={result} close={setResult_open} timeout={1000} ></ResultPopup> ) : <> {result_open} </>}
        </div>
    )
}
export default FindInfo;