import React, { useState, useEffect, useRef, useCallback } from 'react';
import reactStringReplace from 'react-string-replace';

import axios from "axios";
import Moment from 'react-moment';
import moment from 'moment';
import { getCookie } from '../ReactCookie';
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useOnWindowResize } from "rooks"


import '../css/callList.css';
import '../css/popup.css';
import '../css/calendar.css';

import { MdOutlineArrowBackIos } from "react-icons/md";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Calendar from 'react-calendar';
//import DateRangePicker from '@wojtekmaj/react-daterange-picker'

import dayjs from 'dayjs';
import { Tooltip } from 'react-tippy';

import * as Func from './common/Func'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';


const CallType = [
    {
        label: '수신',
        value: 0,
        name: "callIn" ,
    },
    {
        label: '발신',
        value: 1,
        name: "callOut" ,
    },
    {
        label: '부재',
        value: 2,
        name: "callMiss" ,
    },
    {
        label: '내선',
        value: 3,
        name: "callExtension" ,
    },
  ]

const CallList = (props) => {
    const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };
    
    let access_token = getCookie("access_token_user");
    const login_id = props.cookie.login_id;
    //const callListHeight = window.innerHeight - 168.7;

    /*
    // 통화유형 조건 추가 전 height 설정
        const callListHeight = window.innerHeight - 278.7;
        const callListHeight_popup = window.innerHeight - 238.7;
    */

    const before_callListHeight = window.innerHeight - 288.7;
    const before_callListHeight_popup = window.innerHeight - 248.7;

    // const callListHeight = window.innerHeight - 304;
    // const callListHeight_popup = window.innerHeight - 244;
    const callListHeight = window.innerHeight - 318.7;
    const callListHeight_popup = window.innerHeight - 278.7;


    const [openFilter, setOpenFilter] = useState(false);

    //const locale = 'ko';

    const [mode, setMode] = useState('day')
    const [loading, setLoading] = useState(true)
    const [pageLoading, setPageLoading] = useState(true)
    const [error, setError] = useState(false)
    
    //const [data, setData] = useState({ data: [] });
    //const [data, setData] = useState([]);
    const [datas, setDatas]     = useState([])
    const [popupListDatas, setPopupListDatas] = useState([]);
    //const prevPopupListDatas = usePrevious(popupListDatas);
    
    const [moreThanOne, setMoreThanOne] = useState(false);
    const [listData, setListData] = useState('');

    const [refreshKey, setRefreshKey] = useState(0);
    const [calendarKey, setCalendarKey] = useState(0);

    const prevRefreshKey = usePrevious(refreshKey);
    const prevCalendarKey = usePrevious(calendarKey);
    
    const [open, setOpen] = useState(false);    
    const [callListAudioOpen, setCallListAudioOpen] = useState([false, false]);
    const [callListOpen, setCallListOpen] = useState([false, false]);
    const [callView, setCallView] = useState(false);
    const [popupData, setPopupData] = useState({ popupData: [] });   
    

    //달력1
    //const [value, onChange] = useState(new Date());

    //달력2
    const [value, onChange] = useState([new Date(), new Date()]);

    const dateArray = ["일", "월", "화", "수", "목", "금", "토"]
    const today = new Date();   
    const year = today.getFullYear(); // 년도
    const month = today.getMonth() + 1;  // 월
    let date = today.getDate();  // 날짜
    const day = today.getDay();  // 요일
    if (date < 10) { date = "0" + date; }

    const [nowDate, setNowDate] = useState(moment(today).format('YYYY-MM-DD') + ' (' + dateArray[moment(today).day()] + ')' );
    const [thisMonth, setThisMonth] = useState();

    const { pathname } = useLocation();
    

    useOnWindowResize(() => setRefreshKey(refreshKey+1));


    // 페이징
    const [query, setQuery] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [popPageNumber, setPopPageNumber] = useState(1)
    
    const [hasMore, setHasMore] = useState(false)
    const [popHasMore, setPopHasMore] = useState(false)    
    
    const observer = useRef()
    const observer2 = useRef()

    const containerRef = useCallback(node => {
        if (pageLoading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            ////const [ entry ] = entries
        ////(entry.isIntersecting)
            if (entries[0].isIntersecting && hasMore) {   
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if(node) observer.current.observe(node)
    }, [pageLoading, hasMore])
    
    const popContainerRef = useCallback(node => {
        if (pageLoading) return
        if (observer2.current) observer2.current.disconnect()
        observer2.current = new IntersectionObserver(entries => {                     
            if (entries[0].isIntersecting && popHasMore) {
                setPopPageNumber(prevPopPageNumber => prevPopPageNumber + 1)
            }
        })
        if(node) observer2.current.observe(node)
    }, [pageLoading, popHasMore])

    /*
    const [finishStatus, setfinishStatus] = useState(false);

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        if (!finishStatus) {

            console.log("test123" + callView);

            if (callView === true) { 
                console.log("test456");

                if (window.confirm("Do you want to go back ?")) {
                    console.log("test1")
                    setfinishStatus(true)                
                    props.history.push("/");                
                } else {
                    console.log("test2")
                    window.history.pushState(null, null, window.location.pathname);
                    setfinishStatus(false)
                }
            }
        }
    }

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);  
        };
    }, []);
    */

    /*
    const [ locationKeys, setLocationKeys ] = useState([])
    const history = useHistory();
    
    useEffect(() => {
        return history.listen(location => {
            if (history.action === 'PUSH') {
                setLocationKeys([ location.key ])
            }

            if (history.action === 'POP') {
            if (locationKeys[1] === location.key) {
                setLocationKeys(([ _, ...keys ]) => keys)
                alert("터치1")
                return; 
                // Handle forward event

            } else {
                setLocationKeys((keys) => [ location.key, ...keys ])
                alert("터치2")
                return false;
                // Handle back event

            }
            }
        })
    }, [ locationKeys, ])
     */

    // 통화유형 조건
    const [checkedCallType, setCheckedCallType] = useState([0,1,2,3]);
    const handleCheckedType = (checked, obj) => {
        if(checked) setCheckedCallType(prev => [...prev, obj])
        else {
            if((checkedCallType.includes(0) && !checkedCallType.includes(1) && obj === 0) || (checkedCallType.includes(1) && !checkedCallType.includes(0) && obj === 1)) alert('수신, 발신 둘 중 하나 이상 반드시 포함되어야 합니다.')
            // if(checkedCallType.length === 1 && checkedCallType.includes(obj)) alert('조건은 1개 이상 선택되어야 합니다.')
            else setCheckedCallType(checkedCallType.filter((el) => el !== obj))
        }
    }
    


    useEffect(() => {

        setCallListOpen([false, false])
        const ourRequest = axios.CancelToken.source() // <-- 1st step
                       
        const fetchData = async (mode, date, query) => {
            
            if (pageNumber > 1) {
                setPageLoading(true)
                setLoading(false)
            } else {
                setPageLoading(false)
                setLoading(true)
            }
            setError(false)

            let from = "";
            let to = "";
            
            if (mode === "day") {
                from = date;
                to = date;
                setNowDate(date + ' ' + '(' + dateArray[moment(date).day()] + ')');
                setMode('day')
            } else {
                from    = moment(date[0]).format('YYYY-MM-DD');
                to      = moment(date[1]).format('YYYY-MM-DD');

                if (from === to) {
                    setMoreThanOne(false);
                    setNowDate(moment(date[0]).format('YYYY-MM-DD') + ' ' + '(' + dateArray[moment(date[0]).day()] + ')');
                    setMode('day')
                } else {
                    setMoreThanOne(true);
                    setListData(moment(date[1]).format('YYYY-MM-DD'));
                    setNowDate(moment(date[0]).format('YYYY-MM-DD') + ' ' + '(' + dateArray[moment(date[0]).day()] + ')' + ' ~ ' + moment(date[1]).format('YYYY-MM-DD') + ' ' + '(' + dateArray[moment(date[1]).day()] + ')');
                    setMode('calendar')
                }                
            }
            
            let cancel

            await axios.get('/mgr/authlines/list/' + props.cookie.local_number, {
                headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
                },
                params: {
                    local_number: props.cookie.local_number,
                    corp_idx: props.cookie.corp_idx
                }
            }).then(res => {
                if (res.data.res_code === 200) { 
                    axios({
                        method: 'GET',
                        url: '/mgr/records/list',
                        params: {
                            from: from,
                            to: to,
                            page: pageNumber,
                            limit: 20,
                            searchData: query,
                            corp_idx: props.cookie.corp_idx,
                            line_number: props.cookie.line_number,
                            local_number: props.cookie.local_number,
                            line_auth_level: res.data.data.line_auth_level,
                            acc_auth: res.data.data.acc_auth,
                            group_name: res.data.data.group_name,
                            call_type: checkedCallType.join(','),
                        },
                        headers: {
                            'Authorization': 'Bearer ' + access_token,
                            'Content-Type' : 'application/json'
                        },
                        cancelToken: ourRequest.token, // <-- 2nd step
                        //cancelToken: new axios.CancelToken(c => cancel = c)
                    }).then(res => {
                        if (res.data.data && res.data.data.length > 0) {
                            if (from === to) {
                                //setDatas(res.data.data);
                                setDatas(prevDatas => {
                                    return [...new Set([...prevDatas, ...res.data.data.map(b => b)])]
                                })                        
                            } else {

                                if (res.data.total_count > 0) {
                                    const json_groupby = res.data.data.reduce((r, { in_date: name, ...object }) => {
                                        var temp = r.find(o => o.name === name)
                                        if (!temp) r.push(temp = { name, children: [] })
                                        temp.children.push(object)
                                        return r
                                    }, []);
                                    setDatas(prevDatas => {
                                        return [...new Set([...prevDatas, ...json_groupby.map(b => b)])]                                
                                    })
                                } else {
                                    setDatas([]);
                                }
                            }
                            setHasMore(res.data.total_count > 0)
                        
                        } else {
                            console.log("NODATA")
                            setHasMore(false)
                        }

                        setLoading(false)
                        setPageLoading(false)
                        
                    }).catch(e => {
                        //if (axios.isCancel(e)) return
                        console.log("에러 : " + e);
                        //setError(true)
                    })
                } else {
                    // console.log(res.data.data);
                    return;
                }
            }).catch(error => {
                console.log("회선목록 조회실패");
                console.log(error);
            })

        }
        
        let searchDate = nowDate.split(' ');        
        searchDate = searchDate[0].replace(/\./gi, "-");
        
        if (pageNumber > 1) {
            if (mode === 'day') {
                fetchData('day', searchDate, query);
            } else {
                fetchData('calendar',value, query);
            }
        } else {
            /*
            //하루 전,후 데이터 조회
            if ((prevRefreshKey !== refreshKey) || (prevRefreshKey === 0 && prevCalendarKey === 0)) {                
                setDatas([])                
                fetchData('day', searchDate, query);                
                console.log("A "  + query)
            }
            
            //달력 날짜 변경했을때
            if (prevCalendarKey !== calendarKey && calendarKey > 0) {
                setDatas([])                
                fetchData('calendar', value, query);
                console.log("B "  + query)
            }
            */
            
            if (mode === 'day') {
                setDatas([])                
                fetchData('day', searchDate, query);
            } else if(mode === 'calendar') {
                setDatas([])                
                fetchData('calendar', value, query);
            }
        }        
        
        return () => {                        
            ourRequest.cancel() // <-- 3rd step
        }

    }, [query, pageNumber, refreshKey, calendarKey]);

    useEffect(() => {
        if(pageNumber === 1) setRefreshKey(refreshKey+1)
        else setPageNumber(1)
    }, [checkedCallType])
    

    //통화 상세내역 클릭시 , 해당 고객과의 수신내역    
    useEffect(() => {

        const fetchData = async (peer_number, local_number) => {
            if (popPageNumber > 1) {
                setPageLoading(true)                
            } else {
                setPageLoading(false)                
            }

            setError(false)        
            await axios({
                method: 'GET',
                url: "/mgr/records/list",
                params: {
                    corp_idx: props.cookie.corp_idx,
                    acc_auth: props.cookie.acc_auth,
                    peer_number: peer_number,
                    local_number: local_number,                    
                    limit: 20,
                    page: popPageNumber,
                },
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type' : 'application/json'
                }
            }).then(res => {

                if (res.data.data && res.data.data.length > 0) {                    
                    if (res.data.total_count > 0) {                        
                        // console.log(res.data);
                        const json_groupby = res.data.data.reduce((r, { in_date: name, ...object }) => {
                            var temp = r.find(o => o.name === name);
                            // console.log(name);
                            if (!temp) r.push(temp = { name, children: [] });
                            temp.children.push(object);
                            // console.log(r);
                            return r;
                        }, []);

                        setPopupListDatas(prevPopupListDatas => {
                            return [...new Set([...prevPopupListDatas, ...json_groupby.map(b => b)])]
                        })

                        
                    } else {
                        setPopupListDatas([])
                    }
                    setPopHasMore(res.data.total_count > 0)
                    
                } else {
                    console.log("NODATA1")
                    setPopHasMore(false)
                }
                
                setPageLoading(false)
                
            }).catch(e => {
                console.log("에러 : " + e);
                setError(true)
            })
        }

        if (callView) {
            fetchData(popupData.peer_number, popupData.local_number);
        }

    }, [popPageNumber, callView]);
    

    const [callListAudioOpenToggle, setCallListAudioOpenToggle] = useState(null);

    const toggleAudioMenu = (e, i) => {
        e.preventDefault()
        /*
        // Clone the array
        const newState2 = callListAudioOpen.slice(0)
        // Toggle the state of the clicked sub-menu
        newState2[i] = !newState2[i]
        // Set the new state
        setCallListAudioOpen(newState2)
        */
        if(callListAudioOpenToggle === i) setCallListAudioOpenToggle(null);
        else setCallListAudioOpenToggle(i);
    }


    const toggleSubMenu = (e, i) => {
        e.preventDefault();
        
        // Clone the array
        const newState = callListOpen.slice(0)
        // Toggle the state of the clicked sub-menu
        newState[i] = !newState[i]
        // Set the new state
        setCallListOpen(newState)
    }    

    const passData = (props) => {                
        if (props !== "") {
            setPopupData(props);
            setCallView(true);
            
        }
    };   

    //<img src={`${process.env.REACT_APP_PUBLIC_URL}/img/bugur.png`}alt="" />
    const Menu = (props) => {        
        if (props.call_type === "in") {            
           return props.call_status === 'OK' ? <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/inBound.png`} alt="" style={{width:'100%', height:'100%'}} />  : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/inCancel.png`} alt="" style={{width:'100%', height:'100%'}}/>;
        } else  if (props.call_type === "out") {
           return props.call_status === 'OK' ? <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/outBound.png`} alt="" style={{width:'100%', height:'100%'}}/>  : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/outCancel.png`} alt="" style={{width:'100%', height:'100%'}}/>;
        } else { 
            return props.call_status === 'OK' ? <></> : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/outCancel.png`} alt="" style={{width:'100%', height:'100%'}}/>;
        }
    }

    //데이터 형식 변경 2021.01.01 => 2021년 01월 01일
    const replaceDate = (name) => {
        //일일치
        if (name.length > 10) {
            name = name.substr(0, 10)            
        }

        if (name.indexOf('-') > 0) {
            let nameArray = name.split('-');            
            return nameArray[0] + "년 " + nameArray[1] + "월 " + nameArray[2] + "일 " + "(" + dateArray[moment(new Date(nameArray[0], nameArray[1] - 1, nameArray[2])).day()] + ")";
        } else { 
            return name;
        }        
    }

    const replaceTime = (in_time) => {
        if (in_time) { 
            let timeArray = in_time.split(':');
            let in_time_r = in_time.replace(/:/gi, "");            
            
            if (parseInt(in_time_r) > 120000) {
                let hour = timeArray[0] - 12;                
                if (hour < 10) { hour = "0" + hour }
                if (hour == "00") { hour = "12";}
                return "오후 \n" + hour + ":"+ timeArray[1] + ":" + timeArray[2];
            } else { 
                return "오전 \n" + timeArray[0] + ":" + timeArray[1] + ":" + timeArray[2];
            }   
        }
    }


    //데이터 형식 변경 01075084129 => 010-7508-4129
    const replaceNumber = (name) => {

        if (name !== undefined && name.length > 0 ) {       

            //5588248
            if (name.length == 7) {

                return name.substr(0, 3) + '-' + name.substr(3, 7);
                
            } else if (name.length == 8) {
                //16618290
                return name.substr(0, 4) + '-' + name.substr(4, 8);

            } else if (name.length == 10) {
                //0216618290
                // return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);

                if(name.indexOf('02')==0) return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);
                else return name.substr(0, 3) + '-' + name.substr(3, 3) + '-' + name.substr(6, 4);

            } else if (name.length == 11) {
                //01075084129
                return name.substr(0, 3) + '-' + name.substr(3, 4) + '-' + name.substr(7, 4);
            } else { 
                return name;
            }
        
        }else {
            return name;
        }          
    }

    //하루치일때는 이걸로 돌리고
    const CardList = () => {
        return (
            <div style={{ background: '#f2f2f2', paddingTop: '12px' }}>
                <p style={{ fontSize: '.785rem', margin: '0 0 8px 20px', color: '#515151' }}>{replaceDate(nowDate)}</p>
                <Card className="text-start" style={{ margin: '0 14px 0 14px' }}>                    
                    {                        
                        datas && datas.map((board, index) =>
                          <div id={`cardList_${board.record_idx}`} key={`cardList_${index}`}>
                            <Card.Body className="p-2" >
                                <div className="d-flex flex-row align-items-center" onClick={(e) => toggleSubMenu(e, board.record_idx)} style={{ justifyContent:'space-between' }}>
                                    {/* onClick={e => toggleSubMenu(e, 0)} */}
                                    <div style={{ width:'10%' }}>
                                        <Menu call_type={board.call_type} call_status={board.call_status} />
                                    </div>
                                    <div className="" style={{ width:'45%' , fontSize: '.8rem', fontWeight: '500', color: '#515151', marginLeft: '5px', }}>{replaceNumber(board.peer_number)}</div>
                                        {/* <div className="" style={{ width: '15%', fontSize: '.8rem', fontWeight: '500', color: '#515151', marginTop: '5px' }}>({board.local_number})</div> */}
                                        <div className="" style={{ width: '18%', fontSize: '.8rem', fontWeight: '500', color: '#515151',  }}>
                                            { callListOpen[board.record_idx] === true ?
                                                board.line_name
                                            :
                                                <Tooltip html={(<span style={{whiteSpace: 'pre'}}>{board.local_number}</span>)}
                                                style={{display: 'inline',  height:'100%', whiteSpace: 'pre'}} touchHold={true}
                                                size='small' position="bottom" trigger="mouseenter">
                                                    {board.line_name}
                                                </Tooltip>
                                            }
                                        </div>
                                        
                                        <div className="" style={{ width:'25%', fontSize: '.6775rem', fontWeight: '500', color: '#515151', textAlign:'left', paddingLeft:'15px', whiteSpace: 'pre-wrap' }}>                                            
                                        {replaceTime(board.in_time)}
                                        </div>
                                        
                                    <div className="" style={{ width:'15%',  textAlign:'center' }}>
                                            {board.mp3_size > 0 ? <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/record(30_30)_72-01.png`} alt="" style={{width:'25px', marginTop:'2px'}}/> : ''}
                                    </div>
                                    
                                </div>
                                {/* toggle On Off Menu */}
                                <div style={{ display: callListOpen[board.record_idx] === true ? "block" : "none" }}>
                                        <div className="d-flex">                                           
                                            <div style={{ width:'10%' }}></div>
                                            <div style={{ width:'45%' , fontSize: '.6775rem', fontWeight: '500', color: '#515151', marginTop: '5px', marginLeft:'5px'}}>
                                                {board.call_type === "in" ? <><span>수신</span></> : <><span>발신</span></>}
                                                전화, {board.duration}초
                                            </div>  
                                            <div style={{ width: '18%', fontSize: '.6775rem', fontWeight: '500', color: '#515151', marginTop: '5px', textAlign:'left', paddingLeft: '1px' }}>
                                                 {board.local_number}
                                            </div>

                                        {/***  통화내역 아이콘 위치 (시간아래)  ***/}
                                            {/* <div className="d-flex justify-content-start" style={{ width:'30%',  textAlign:'center', paddingLeft:'15px' }}>
                                                <a href={`tel:${board.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(board) }} style={{ width: '20px', marginLeft: '13px'}}/>
                                            </div> */}
                                            {/* <div className="d-grid" style={{  width:'10%', fontSize: '.6775rem', fontWeight: '500', textAlign:'right', gridTemplateColumns:'1fr 1fr', gridGap:'10px' }}> */}
                                                {/* <a href={`tel:${board.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(board) }} style={{ width: '20px'}}/> */}
                                            {/* </div> */}
                                        {/***  *****************************  ***/}
                                           
                                        {/***  통화내역 아이콘 위치수정 (22-05-10)  ***/}
                                            <div className="d-flex justify-content-end" style={{ width:'25%',  textAlign:'center', paddingLeft:'15px', paddingRight:'10px' }}>
                                                <a href={`tel:${board.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                            </div>
                                            <div className="d-flex justify-content-center" style={{  width:'15%', fontSize: '.6775rem', fontWeight: '500' }}>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(board); }} style={{ width: '20px'}}/>
                                            </div>
                                        {/***  *****************************  ***/}
                                        </div>
                                </div>
                            </Card.Body>
                            <div style={{ width: '90%', height: '1px', background: '#f7f7f7', margin: 'auto' }}></div>
                        </div>
                        )
                    }
                </Card>
                <div ref={containerRef}></div>
            </div>
        )
    };
    
    
    //2일 이상 데이터일때는 이걸로 돌리기
    const CardList2 = () => {
            
        return (
            <div style={{ background: '#f2f2f2', paddingTop: '12px' }}>
                {datas && datas.map((board, index) => 
                <div style={{ paddingBottom:'12px' }} id={`cardList2_${index}`} key={`cardList2_${index}`}>
                    <p style={{ fontSize: '.785rem', margin: '0 0 8px 20px', color: '#515151' }}>{replaceDate(board.name)}</p>
                    <Card className="text-start" style={{ margin: '0 14px 0 14px' }} key={board.record_idx}  >
                        {
                          board.children && board.children.map(children => (
                            <div id={`c2_child_${children.record_idx}`} key={`c2_child_${children.record_idx}`}>
                                <Card.Body className="p-2" >
                                    <div className="d-flex flex-row align-items-center" onClick={(e) => toggleSubMenu(e, children.record_idx)}>
                                        <div style={{ width:'10%' }}>
                                            <Menu call_type={children.call_type} call_status={children.call_status} />
                                        </div>
                                        <div className="" style={{ width:'55%', fontSize: '.8rem', fontWeight: '500', color: '#515151', marginLeft: '5px' }}>{replaceNumber(children.peer_number)}</div>
                                          {/* <div className="" style={{ width: '15%', fontSize: '.8rem', fontWeight: '500', color: '#515151', marginTop: '5px', marginLeft: '5px', }}>({children.local_number})</div> */}
                                          <div className="" style={{ width: '22%', fontSize: '.8rem', fontWeight: '500', color: '#515151', }}>
                                            { callListOpen[children.record_idx] === true ?
                                                children.line_name
                                            :
                                                <Tooltip html={(<span style={{whiteSpace: 'pre'}}>{children.local_number}</span>)}
                                                    style={{display: 'inline',  height:'100%', whiteSpace: 'pre'}} touchHold={true}
                                                    size='small' position="bottom" trigger="mouseenter">
                                                        {children.line_name}
                                                </Tooltip>
                                            }                                          
                                          </div>
                                          
                                        <div className="" style={{ width:'30%', fontSize: '.6775rem', fontWeight: '500', color: '#515151', textAlign:'left', paddingLeft:'15px', whiteSpace: 'pre-wrap' }}>
                                            {replaceTime(children.in_time)}
                                        </div>
                                        <div className="" style={{ width:'15%', textAlign:'center' }}>
                                            {children.mp3_size > 0 ? <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/record(30_30)_72-01.png`} alt=""  style={{width:'25px'}} /> : ''}
                                        </div>
                                    </div>
                                    {/* toggle On Off Menu */}
                                    <div style={{ display: callListOpen[children.record_idx] === true ? "block" : "none" }}>
                                        <div className="d-flex">                                           
                                            <div style={{ width:'10%' }}></div>
                                            <div style={{ width:'55%' , fontSize: '.6775rem', fontWeight: '500', color: '#515151', marginTop: '5px', marginLeft:'5px'}}>
                                                {children.call_type === "in" ? <><span>수신</span></> : <><span>발신</span></>}
                                                전화, {children.duration}초
                                            </div>  
                                            <div style={{ width: '22%', fontSize: '.6775rem', fontWeight: '500', color: '#515151', marginTop: '5px', textAlign:'left',  paddingLeft: '1px' }}>
                                                 {children.local_number}
                                            </div>
                                            {/* <div className="" style={{ width:'30%',  textAlign:'center' }}>
                                                    
                                            </div> */}
                                        {/***  통화내역 아이콘 위치 (시간아래)  ***/}
                                            {/* <div className="d-flex justify-content-start" style={{ width:'30%',  textAlign:'center', paddingLeft:'15px' }}>
                                                <a href={`tel:${children.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(children) }} style={{ width: '20px', marginLeft: '13px'}}/>
                                            </div> */}
                                            {/* <div className="d-grid" style={{  width:'15%', fontSize: '.6775rem', fontWeight: '500', textAlign:'right', gridTemplateColumns:'1fr 1fr', gridGap:'5px' }}> */}
                                                {/* <a href={`tel:${children.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(children) }} style={{ width: '20px'}}/> */}
                                            {/* </div> */}
                                        {/***  *****************************  ***/}
                                           
                                        {/***  통화내역 아이콘 위치수정 (22-05-10)  ***/}
                                            <div className="d-flex justify-content-end" style={{ width:'30%',  textAlign:'center', paddingLeft:'15px', paddingRight:'10px' }}>
                                                <a href={`tel:${children.peer_number}`} style={{display:'block', width:'20px', height:'20px'}}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{  width:'100%' }} /></a>
                                            </div>
                                            <div className="d-flex justify-content-center" style={{  width:'15%', fontSize: '.6775rem', fontWeight: '500' }}>
                                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/detailButton_big.png`} alt="" onClick={() => { passData(children) }} style={{ width: '20px'}}/>
                                            </div>
                                        {/***  *****************************  ***/}
                                        </div>
                                    </div>
                                </Card.Body>
                                <div style={{ width: '90%', height: '1px', background: '#f7f7f7', margin: 'auto' }}></div>
                            </div>
                        ))}
                        </Card>
                </div>
                )}
                <div ref={containerRef}></div>
            </div>
       )
    }
    
    const selectDate = (type) => {
        
        const DateDay = nowDate.split('-');
        let pageDate = "";        
        let pageYear  = parseInt(DateDay[0]);
        let pageMonth = parseInt(DateDay[1]);
        let pageDay = parseInt(DateDay[2]);
        let pageDay2 = "";
        if (type === 'today') {            
            setNowDate(moment(today).format('YYYY-MM-DD') + ' (' + dateArray[moment(today).day()] + ')');
        } else {
             if (type === 'prev') {
                let d = new Date(pageYear, pageMonth - 1, pageDay - 1);
                pageDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().substr(0, 11);
                pageDay2 = dateArray[moment(new Date(pageYear, pageMonth - 1, pageDay - 1)).day()];
                //pageDay2 = new Date(pageYear, pageMonth - 1, pageDay - 1).getDay();
            } else{
                let d = new Date(pageYear, pageMonth - 1, pageDay + 1);
                pageDate = new Date(d.getTime() - (d.getTimezoneOffset() * 60000)).toISOString().substr(0, 11);
                pageDay2 = dateArray[moment(new Date(pageYear, pageMonth - 1, pageDay + 1)).day()];
                //pageDay2 = new Date(pageYear, pageMonth - 1, pageDay + 1).getDay();
            }

            pageDate = pageDate.slice(0, -1).replace(/(\s*)/g, "");

            // eslint-disable-next-line no-useless-concat
            onChange([new Date(pageYear, pageMonth - 1, pageDay - 1), new Date(pageYear, pageMonth - 1, pageDay - 1)]);
            setNowDate(pageDate + ' ' + '(' + pageDay2 + ')');
        }
       
        setPageNumber(1)
        setMoreThanOne(false)
        setMode('day')
        setRefreshKey(refreshKey + 1)
    }
    

    const clickme = () => {
        window.scrollTo(0,0);
    }

    const search = () => {
        alert("");
    }

    const [styleon, setStyleon] = useState(false);
    const dateStyle = {
        color: '#ff004d',
        fontWeight: '600'
    }

    useEffect(() => {
        setStyleon(true);
    }, [value])

    //날짜 클릭시 레이어 팝업 달력
    const CalendarPopup = () => {
        return (
            <div className="popup" style={{ display: open === true ? "block" : "none" }}>                
            {/* <div className="popup" style={{ display: "block"}}>                 */}
                <div className="popup_inner_calendar">
                    <div className="popup_inner_header">
                        <button type="button" style={{ width:'80px' }} onClick={() => calendar('month')}><span>이번달</span></button>
                        <button type="button" style={{ width:'80px' }} onClick={() => calendar('today')}><span>오늘</span></button>
                    </div>

                    <div className="selectedDate" style={styleon ? dateStyle : null}>
                        <Moment format="YYYY/MM/DD" className="p-1">{value[0]}</Moment>
                         ~ 
                        <Moment format="YYYY/MM/DD" className="p-1">{value[1]}</Moment>
                    </div>

                    <Calendar
                        onChange={onChange}
                        value={value || ''}
                        calendarType="US"
                        selectRange={true}
                        next2Label={null}
                        prev2Label={null}
                        defaultView="month"
                        //defaultValue={new Date(2018,10,1)}
                        formatDay={(locale, date) => dayjs(date).format('D')}
                        //activeStartDate={(new Date(2017,0,1))}
                        activeStartDate={thisMonth}
                        //onClickMonth={(value, event) => alert('Clicked month: ', value)}
                        //onChange={(value, event) => alert('New date is: ', value)}
                        style={{marginBottom: '10px', fontSize: '13px'}}
                    />

                    <div className="popup_cal_inner_bottom d-flex justify-content-center text-center align-center">                        
                        <button className="calendarBtn text-center" disabled={(value.length === 0 ? true : false)} onClick={() => { setMode('calendar'); setCalendarKey(calendarKey + 1); setPageNumber(1); setOpen(false); setStyleon(false) }}>
                            <IoIosCheckmark />
                        </button>
                        <button className="calendarBtn text-center" onClick={() => {setOpen(false); setStyleon(false)}}>
                            <IoIosClose />
                        </button>                        
                    </div>
                </div>
            </div>
        )
    }
    
    //달력 이번달 , 오늘
    const calendar = (type) => {
        if (type === "month") {
            onChange([new Date(today.getFullYear(), today.getMonth(), 1), new Date(today.getFullYear(), today.getMonth()+1, 0)]);
        } else {
            onChange([new Date(year + '-' + month + '-' + date), new Date(year + '-' + month + '-' + date)]);
        }        
    }
    
    //상세 내역 리스트
    const CallViewList = () => {
        return (
            <div style={{ background: '#f2f2f2', paddingTop: '12px', fontWeight: '500' }}>
                {popupListDatas && popupListDatas.map((board, index) => 
                    <div style={{ paddingBottom:'12px' }} id={`callviewlist_${index}`} key={`callviewlist_${index}`}>
                        <p style={{ fontSize: '.785rem', margin: '0 0 8px 20px', color: '#515151' }}>
                            {replaceDate(board.name)}
                        </p>
                        <Card className="text-start" style={{ margin: '0 14px 0 14px' }}>
                        {board.children && board.children.map(children => (
                                <div id={`cv_child_${children.record_idx}`} key={`cv_child_${children.record_idx}`} >
                                <Card.Body className="p-2"  >
                                    <div className="d-flex align-items-center" onClick={(e) => children.mp3_size > 0 ? toggleAudioMenu(e, children.record_idx) : e.preventDefault}>
                                        <div className="d-flex align-items-center" style={{ width: '55%' }}>
                                            <div style={{ width:'30px', height:'30px' }}>
                                                <Menu call_type={children.call_type} call_status={children.call_status} />
                                            </div>
                                            <div style={{ fontWeight: '500', color: '#515151', paddingLeft:'4px' }}>
                                                <p style={{ fontSize: '.8rem', marginBottom:'5px', }}>{replaceTime(children.in_time)}</p>
                                                <p className="m-0" style={{ fontSize: '.6775rem', color:'#515151' }}>
                                                    {children.call_type === "in" ? <>수신</> : <>발신</>}전화, {children.duration}초
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ fontWeight: '500', color: '#515151', paddingLeft:'4px' }}>
                                            <p style={{ fontSize: '.8rem', marginBottom: '5px' }}>{children.line_name}</p>
                                            <p className="m-0" style={{ fontSize: '.6775rem', color:'#515151' }}>
                                              {children.local_number}
                                            </p>
                                        </div>
                                        {/* <div className="ms-auto" onClick={(e) => toggleAudioMenu(e, children.record_idx)} > */}
                                        <div className="ms-auto" >
                                            {children.mp3_size > 0 ? <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/record(30_30)_72-01.png`} alt="" style={{ margin: '0 8px 0 0' }} /> : ''}
                                        </div>
                                    </div>
                                    {/* toggle On Off Menu */}
                                    {/* <div style={{ display: callListAudioOpen[children.record_idx] === true ? "block" : "none" }}>                                    
                                        <div style={{marginTop:'5px'}}>
                                            {(props.cookie.exe_auth > 1 && props.cookie.exe_auth !== 4 && props.cookie.exe_auth !== 5) 
                                            ?<audio type='audio/mpeg' useref="audio_tag" src={`${process.env.REACT_APP_FILE_SERVER}/mgr/mp3/down/${children.record_idx}`} controls style={{width:'100%'}}/>
                                            :<audio type='audio/mpeg' useref="audio_tag" src={`${process.env.REACT_APP_FILE_SERVER}/mgr/mp3/play/${children.record_idx}`} controls controlsList="nodownload" style={{width:'100%'}}/>
                                            }
                                        </div>                          
                                    </div>    */}
                                    {   callListAudioOpenToggle === children.record_idx ?                     
                                            <div>                                    
                                                <div style={{marginTop:'5px'}}>
                                                    {(props.cookie.exe_auth > 1 && props.cookie.exe_auth !== 4 && props.cookie.exe_auth !== 5) 
                                                    ?<audio type='audio/mpeg' useref="audio_tag" src={`${process.env.REACT_APP_FILE_SERVER}/mgr/mp3/down/${children.record_idx}`} controls style={{width:'100%'}}/>
                                                    :<audio type='audio/mpeg' useref="audio_tag" src={`${process.env.REACT_APP_FILE_SERVER}/mgr/mp3/play/${children.record_idx}`} controls controlsList="nodownload" style={{width:'100%'}}/>
                                                    }
                                                </div>                          
                                            </div>   
                                        : null
                                    }
                                </Card.Body>
                                <div style={{ width: '90%', height: '1px', background: '#f7f7f7', margin: 'auto' }}></div>
                                </div>
                        ))}
                        </Card>
                    </div>
                )}                
            <div ref={popContainerRef}></div>
            </div>
       )
    }

    function handleSearch(e) {        
        setQuery(e.target.value)        
        setPageNumber(1)
    }


    return (
        <div style={{ height: '100%', background: '#f2f2f2' }}>
            {/* height: '115px' */}
            <div style={{ height: openFilter ? '155px' : '125px', transition: 'height 0.3s ease-in', padding: '28px 10px 16px 10px',position:'relative', zIndex:'2',  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.06)', background: '#fff' }}>
                <div className="d-flex justify-content-between" style={{ fontSize: nowDate.length > 15 ? "13px" : "15px", fontWeight:'600', padding:'0 0 0 4px'}}>
                    <div>
                        {/* <MdOutlineArrowBackIos  /> */}
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/forwardArrow.png`} alt="" onClick={() => selectDate('prev')} />
                    </div>
                    {/* setThisMonth('');  */}
                    <div className="callListDateTitle text-center" style={{ marginTop: '1px', cursor: 'pointer' }} onClick={() => { setOpen(true); }}>                        
                        <img className="callListDateImg" alt="calendar" src={`${process.env.REACT_APP_PUBLIC_URL}/img/calendar.png`} style={{ width: '18px', marginRight:'10px' }} />
                        <span className="callListDate">{nowDate}</span>                        
                    </div>
                    <div>
                        {/* <MdOutlineArrowForwardIos  /> */}
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/nextArrow.png`} alt="" onClick={() => selectDate('next')} />
                    </div>
                </div>

                <div className="searchForm">
                    <Form.Control className="form-control input-search" type="text" value={query || ''} onChange={handleSearch}/>
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/search_24px.png`} className="input-search-icon" alt="" onClick={() => search('') } />
                </div>

                <div className="searchForm d-flex justify-content-between" style={{height: openFilter ? '40px' : 0,  transition: 'height 0.3s ease-in', overflow: 'hidden'}}>
                    {
                        CallType.map(el => {
                            return (
                                <div className='callTypeLabel' key={el.name}>
                                    <input className='blind' type="checkbox" name={el.name} id={el.name} value={el.value} 
                                        checked={checkedCallType.includes(el.value)}
                                        onChange={(e) => handleCheckedType(e.target.checked, el.value)}  />
                                    <label htmlFor={el.name}>{el.label}</label>
                                </div>
                            )
                        })
                    }
                </div>

                <div style={{width: '100%', textAlign: 'center', position: 'absolute', bottom: 0, left: 0}} onClick={() => setOpenFilter(!openFilter)}>
                    <FontAwesomeIcon icon={faMinus} color={openFilter ? '#c7c7c7' : '#383838'} />
                </div>
            </div>

            {callView === true ? (
                <div className="popup" >
                    <div className="callView_popup_inner">
                        <div className="topBar"></div>                        
                        <div style={{ background:'#FFFFFF', height:'100%' }} >
                            <div style={{ position:'relative', zIndex:'2', height:'143px', boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.06)' }}>                            
                                <div className="d-flex justify-content-between" style={{ height:'40px', textAlign: 'right', paddingRight: '15px', paddingTop:'3px' }}>
                                    <div style={{ fontSize: '16px', color: '#383838', fontWeight: '700', margin: '15px 0 0 24px' }}>
                                        <MdOutlineArrowBackIos onClick={() => { setPopupListDatas([]); setCallView(false); setCallListAudioOpen([false, false]); setPopPageNumber(1); setCallListAudioOpenToggle(null) }} />
                                    </div>
                                    <div>
                                        {/* <span style={{ fontSize: '30px', cursor: 'pointer' }} ><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/bugur.png`} alt="" /></span> */}
                                    </div>               
                                </div>
                                <div className="text-center" style={{ margin:'15px 0 18px 0', fontSize:'1.125rem', fontWeight:'800' }}>
                                    <p style={{ marginBottom: '12px' }}>{Func.replaceNumber(popupData.peer_number)}</p>
                                    {/* <p style={{ marginBottom: '12px' }}>{popupData.peer_number} ({popupData.local_number})</p> */}
                                    {/* <a href={`tel:${popupData.line_number}`}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton.png`} alt="" style={{ marginRight: '4.5px' }} /></a> */}
                                    <a href={`tel:${popupData.peer_number}`}><img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callButton_big.png`} alt="" style={{ width:'28px', height:'28px', marginRight: '4.5px' }} /></a>
                                </div>
                            </div>

                            {/* 통화내역 리스트 */}
                            {
                                popupListDatas && popupListDatas.length ? (
                                    <div style={{ height: callListHeight_popup+40, overflowY: 'scroll', paddingBottom: '30px', background: '#f2f2f2' }} >
                                        <CallViewList/>
                                    </div>    
                                ) : <div style={{ height: callListHeight_popup+40 }}>
                                        <Card className="text-center p-2 m-2">통화내역이 없습니다.</Card>
                                    </div>
                            }
                            <div style={{background: '#f2f2f2', height:'100%'}}></div>
                        </div>
                    </div>                
                    
                    <div className="footer">
                        <div className="footer-img">
                            <NavLink exact to="/" isActive={() => ['/'].includes(pathname)} >
                                {pathname == "/" ?
                                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/home_hover.png`} alt="" style={{ width : '30px'}}/>
                            : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/home.png`} alt="" style={{ width : '30px'}}/>}                
                            </NavLink>
                        </div>
                        <div className="footer-img">
                            <NavLink exact to="/callList" isActive={() => ['/callList'].includes(pathname)} >
                                {pathname == "/callList" ?
                                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callList_hover.png`} alt="" style={{ width : '38px'}}/>
                            : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callList.png`} alt="" style={{ width : '38px'}}/>}                
                            </NavLink>
                        </div>
                        <div className="footer-img">
                            <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/back.png`} alt="" onClick={() => { setPopupListDatas([]); setCallView(false); setCallListAudioOpen([false, false]); setPopPageNumber(1); setCallListAudioOpenToggle(null) }} style={{ width : '38px'}}/>
                        </div>
                    </div>

                </div>
            ) : <></>
            }            

            <CalendarPopup/>            
            <div>{error && <Card className="text-center p-2 m-2 ">에러발생</Card>}</div>         
            <div style={{ height: (openFilter ? callListHeight : before_callListHeight) + 30 , background:'#f2f2f2', transition: 'height 0.3s ease-in' }}>                
                {   
                    loading && 'Loading...' ? (
                        <Card className="text-center p-2 m-2 ">데이터 로딩중 ...</Card>
                    ) : datas && datas.length ? (
                            <div style={{ height: (openFilter ? callListHeight_popup : before_callListHeight_popup)-40, transition: 'height 0.3s ease-in', overflowY: 'scroll', paddingBottom: '30px', background: '#f2f2f2' }}>
                                {moreThanOne === true ? <CardList2 /> : <CardList />}
                            </div>
                        ) : <Card className="text-center p-2 m-2 ">통화내역이 없습니다</Card>
                }                
            </div>            
        </div>
    )
    //         {open === true ? (
    //             <CallListPopup type={"info"} open={open} close={setOpen} popupData={popupData} audioStyle={audioStyle} timeout={1000} ></CallListPopup>
    //         ) : <>{open}</>}
}

export default CallList;