import React, { useState, useEffect } from 'react';
import { Reset } from 'styled-reset'
import {BrowserRouter as Router, Route, Switch, Redirect, useHistory, useLocation} from 'react-router-dom'; 
import { getCookie, getToken, removeCookie } from './ReactCookie';
import 'react-tippy/dist/tippy.css'

import Header from './page/common/Header';
import Footer from './page/common/Footer';
import AuthRoute from './page/login/AuthRoute';
import Home from './page/Home';
import CallList from './page/CallList';
import Statistics from './page/Statistics';

import Login from './page/Login';
import FindInfo from './page/login/FindInfo';
import Auth from './page/Auth';
import NotFound from './page/NotFound';


const App = () => {

  const [user, setUser] = useState(null);  
  const authenticated = user != null;  

  const [cookie_yn,   setCookie_yn] = useState(false);  
  const [cookie_info, setCookie_info] = useState([]);
  const [access_token, setAccess_token] = useState(getCookie("access_token_user"));
  //let access_token = getCookie("access_token_user");

  const clientWidth = document.body.clientWidth;
  const clientHeight = document.body.clientHeight;

  /*
  if (access_token) {  
    if (getToken(access_token).auth_type === "admin") {
      setAccess_token();      
    }
  }
  */  

  const { pathname } = useLocation();
  
  const history = useHistory();  
    
  useEffect(() => {

    //if (!typeof access_token === "undefined" || !access_token == null || !access_token === '') {    
    if (typeof access_token !== "undefined" && access_token !== null && access_token !== '') {

      setUser(access_token);
      setCookie_info(getToken(access_token));
      
      const iat = parseInt(getToken(access_token).iat + "000");
      const exp = parseInt(getToken(access_token).exp + "000");
      
      if (Date.now() > exp) {
        console.log("토큰 유효시간 만료")        
        removeCookie("access_token_user");
        setUser();
        history.push('/login');
      }
    }
  }, [access_token]);
    
  // console.log("App.js : " + pathname, history);  
    
  const loginFunc = (access_token) => {
    setCookie_info(getToken(access_token));
    setUser(access_token);
    setCookie_yn(true);
  }  

  const logoutFunc = () =>{     
    setUser();
    history.push('/login');
  }
  
  return (       
    <Router basename="front">
      <Reset/>  
      {/* {authenticated === false ? <Redirect to="/login" /> : <Redirect to={`${pathname}`} />} */}
      {authenticated === false ? <Redirect to="/login" /> : <></>}      
      {authenticated === false ? <></> : <Header cookie={cookie_info} logoutFunc={logoutFunc}/>}
      
      <Switch>
        <Route path="/login"
          render={props => (
            <Login authenticated={authenticated} loginFunc={loginFunc} {...props} />
          )}
        />

        {/* Home */}
        <AuthRoute
            exact  
            authenticated={authenticated}
            path="/"
            render={props => <Home cookie={cookie_info} user={user} {...props}
             />}  
        />

        <AuthRoute
            exact
            authenticated={authenticated}
            path="/callList"
            render={props => <CallList cookie={cookie_info}  user={user} {...props} />}
        />

        <AuthRoute
            exact
            authenticated={authenticated}
            path="/auth"
            render={props => <Auth cookie={cookie_info}  user={user} {...props} />}
        />

        <AuthRoute
            exact
            authenticated={authenticated}
            path="/statistics"
            render={props => <Statistics cookie={cookie_info}  user={user} {...props} />}
        />
        
        {/* <Route path="/find" component={FindInfo} exact /> */}        
          
        <Route path="/find"
          exact
          render={props => (
            <FindInfo authenticated={authenticated} clientWidth={clientWidth} clientHeight={clientHeight} {...props} />
          )}
        />
        
        <Route component={NotFound} />

      </Switch>

      {authenticated === false ? <></> : <Footer cookie={cookie_info}/>}
    </Router>        
  )
}

export default App;
