import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css'
import './css/index.css';
import './css/button.css';

import '@fortawesome/fontawesome-free/js/all.js';

//import 'react-calendar/dist/DatePicker.css';
//import 'react-calendar/dist/Calendar.css';


ReactDOM.render(
  <BrowserRouter basename={process.env.REACT_APP_PUBLIC_URL}>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);