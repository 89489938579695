/******* 단순히 표시되는 전화번호 하이픈 삽입 *******/
export function replaceNumber(name) {
  if (name !== undefined && name.length > 0 ) {       

    //5588248
    if (name.length == 7) {

        return name.substr(0, 3) + '-' + name.substr(3, 7);
        
    } else if (name.length == 8) {
        //16618290
        return name.substr(0, 4) + '-' + name.substr(4, 8);

    } else if (name.length == 9) {
        // 025588248
        return name.substr(0, 2) + '-' + name.substr(2, 3) + '-' + name.substr(5, 9);

    } else if (name.length == 10) {
        //0216618290
        // return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);

        if(name.indexOf('02')==0) return name.substr(0, 2) + '-' + name.substr(2, 4) + '-' + name.substr(6, 4);
        else return name.substr(0, 3) + '-' + name.substr(3, 3) + '-' + name.substr(6, 4);

    } else if (name.length == 11) {
        if(name.indexOf('050')==0) return name.substr(0, 4) + '-' + name.substr(4, 3) + '-' + name.substr(7, 4);
        //01075084129
        else return name.substr(0, 3) + '-' + name.substr(3, 4) + '-' + name.substr(7, 4);
    } else { 
        return name;
    }

  }else {
      return name;
  }          
}


/******* 입력창 입력 시 전화번호 하이픈 삽입 *******/
export function onChangeNum(value) {
  if (!value) {
    return "";
  }

  value = value.toString().replace(/[^0-9]/g, "");

  let result = [];
  let restNumber = "";

  // 지역번호와 나머지 번호로 나누기
  if (value.startsWith("02")) {
    // 서울 02 지역번호
    result.push(value.substr(0, 2));
    restNumber = value.substring(2);
  } else if (value.startsWith("1")) {
    // 지역 번호가 없는 경우
    // 1xxx-yyyy
    restNumber = value;
  } else if (value.startsWith("0505")) {
    // 0505 000 0000
    result.push(value.substr(0, 4));
    restNumber = value.substring(4);
  } else {
    // 나머지 3자리 지역번호
    // 0xx-yyyy-zzzz
    result.push(value.substr(0, 3));
    restNumber = value.substring(3);
  }

  if (restNumber.length === 7) {
    // 7자리만 남았을 때는 xxx-yyyy
    result.push(restNumber.substring(0, 3));
    result.push(restNumber.substring(3));
  } else {
    result.push(restNumber.substring(0, 4));
    result.push(restNumber.substring(4));
  }
  return result.filter((val) => val).join("-");
}