import React, { useState, useEffect} from 'react';

import {NavLink, Link, useLocation, useHistory} from 'react-router-dom';

import '../../css/header.css';
import { IoIosArrowForward } from "react-icons/io";

import { getCookie, deleteAllCookies} from '../../ReactCookie';

import * as Func from './Func';
   
const Header = (props) => {
    
    let login_id        = props.cookie.login_id;
    let login_name      = props.cookie.login_name;
    let line_auth       = props.cookie.line_auth;
    let local_number    = props.cookie.local_number;
    

    const { pathname } = useLocation();
    const [title, setTitle] = useState('');    

    const activeStyle = {        
        textDecoration: 'underline',
        color: '#D01A6F',
        fontWeight:'600'
    }
            
    const [remember_yn, setRemember_yn] = useState(getCookie("remember_yn"));
    const [isOpen, setIsOpen] = useState(false);   

    const logoutPage = () => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("로그아웃 하시겠습니까?")) {
            //removeCookie("access_token");
            // deleteAllCookies();
            
            if (remember_yn === "false") {
                deleteAllCookies();
            } else {
                //아이디, 비밀번호는 남겨두기
                deleteAllCookies(remember_yn);
            }
            props.logoutFunc();
        } else {
            return;
        }        
    }

    useEffect(() => {
        if (pathname === "/") {
            setTitle('');
        } else if (pathname === "/callList") {
            setTitle('통화내역');
        } else if (pathname === "/auth") {
            setTitle('권한관리');
        } else if (pathname === "/statistics") {
            setTitle('통계');
        }
        
        setIsOpen(false);

    }, [pathname]);
    
    return (    
        <div style={{ background:'#fff' }} >
            <div className="topBar"></div>
            {/* <div style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/img/ping_circle.png)`, backgroundRepeat: 'no-repeat' }}>asdf</div> */}
            <div className="bg" style={{ display: isOpen === true ? "block" : "none" }} onClick={() => setIsOpen(false)}></div>
            <div className="sidenav" style={isOpen ? { width: '230px', boxShadow:'-5px 0 6px 0 rgba(0,0,0,0.16)' } : { width:'0px'}}>
                <div className="sidenavTop">
                    <div className="d-flex justify-content-between" style={{ paddingTop:'21px' }}>
                        <div style={{fontSize: '1.25rem', fontWeight:'500', paddingLeft:'25px', paddingTop:'5px' }}>IQ200</div>
                        <div>                        
                            <span className="closebtn" style={{ color:'white' }} onClick={() => setIsOpen(false)}>&times;</span>
                        </div>
                    </div>

                    <div className="d-flex justify-content-between" style={{ marginTop:'52px' }}>
                        <div>
                            <div style={{ marginLeft:'27px' }}>
                                <p className="mb-2" style={{ fontSize:'.785rem' }}>{login_name}</p>
                                <p style={{ fontSize:'1.125rem',textDecoration: 'underline' }}>{Func.replaceNumber(login_id)} ({local_number})</p>
                            </div>
                        </div>
                        <div style={{ marginTop: '19px', marginRight: '10px', fontSize: '1.25rem'}}>
                            {/* <Link to="/myPage" style={{ color:'white' }}><IoIosArrowForward/></Link> */}
                        </div>                        
                    </div>
                    
                </div>
                <div className="sidenavBottom">
                    <NavLink exact activeStyle={activeStyle} to="/" isActive={() => ['/'].includes(pathname)} >HOME</NavLink>
                    <NavLink exact activeStyle={activeStyle} to="/callList" >통화내역</NavLink>
                    { line_auth === "Y" ? <NavLink exact activeStyle={activeStyle} to="/auth" >권한관리</NavLink> : <></> }                    
                    {/* <NavLink exact activeStyle={activeStyle} to="/statistics" >통계</NavLink> */}
                    <p style={{ fontSize:'17px',paddingBottom:'20px', color:'#383838', fontWeight:'700', cursor:'pointer' }} onClick={logoutPage}>로그아웃</p>
                </div>
            </div>
            <div className="d-flex justify-content-between" style={{height: '60px', textAlign: 'right', paddingRight: '15px', paddingTop: '25px', boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.06)' }}>
                <div style={{ fontSize: '16px', color: '#383838', fontWeight: '700', margin: '5px 0 0 20px' }}>
                    {title}
                </div>
                <div>
                    {/* <span style={{ fontSize: '30px', cursor: 'pointer' }} onClick={() => setIsOpen(true)}>&#9776;</span> */}
                    <span style={{ cursor: 'pointer'}} onClick={() => setIsOpen(true)}>                        
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/bugur.png`} alt="" />
                    </span>
                </div>               
            </div>
        </div>
    )
}

export default Header;