import React, { useState, useRef, useEffect } from 'react';
import axios from "axios";

import { getCookie, getToken } from '../ReactCookie';
import { FowardPopup, ForwardCompletePopup, ForwardErrorPopup, CallFwOnPopup, CallFwOffPopup } from './login/Popup';

import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import '../css/home.css';
import '../css/popup.css';

import * as Func from './common/Func';

const Home = (props) => {
  const fowardBtn = {
    width: '55px',
    height: '22px',
    border: '0',
    borderRadius: '14px',
    position: 'relative',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    boxShadow: 'inset 0 3px 6px 0 rgba(0, 0, 0, 0.16)' 
  }
    
  const [popOpen, setPopupOpen] = useState(false);
  const [forwardCompletePopup, setForwardCompletePopup] = useState(false);  
  const [forwardErrorPopup, setForwardErrorPopup] = useState(false);  
  const [data, setData] = useState();    
  const [lineNumber, setLineNumber] = useState('');
  const [foward_yn, setFoward_yn] = useState('');
  const [fowardData, setFowardData] = useState('');      

  const [toggleBtnOn, setToggleBtnOn] = useState(false);

  const [callForwardon, setCallForwardon] = useState(false);
  const [callForwardoff, setCallForwardoff] = useState(false);


  
  let access_token = getCookie("access_token_user");
  const login_id = props.cookie.login_id;  
  const line_auth = props.cookie.line_auth;
  const corp_idx = props.cookie.corp_idx;  
  const line_idx = props.cookie.line_idx;
  const login_name = props.cookie.login_name;
  const corp_number = props.cookie.corp_number;  

   
  const toggleBtn = () => {
    //번호설정 창 열어주기
    if (toggleBtnOn === false) {
      
      if (lineNumber === "") {
        alert("번호를 먼저 입력해주세요.");
        return;
      } else {
        setToggleBtnOn(true);
        callFowarding();
      }
      
    } else {
      setToggleBtnOn(false);
      stopForwarding();
    }
  }

  useEffect(() => {
    const fetchData = async () => {

      //console.log(access_token);

      const result = await axios({
          method: 'GET',          
          url: `/mgr/centrix/forward/${line_idx}`,
          params: {              
              mode: '',
              destnumber: ''
          },
          headers: {
              'Authorization': 'Bearer ' + access_token,
              'Content-Type' : 'application/json' 
          },
          //data: params
      });

      if (result.data !== "") {
        setData(result.data);

        //착신전환 ON
        if (result.data.mode === "F") {
          setFoward_yn("Y");
          setFowardData(result.data.foward_number);
          setLineNumber(result.data.foward_number);                        
          setToggleBtnOn(true);

        //착신전환 OFF
        } else {
           // foward_number 가져오기
          axios.get(`/mgr/line/` + line_idx, {
            headers: {
              'Authorization': 'Bearer ' + access_token,
              'Content-Type': 'application/json'
            }
          })
          .then(res => {
            setFoward_yn("N");
            setFowardData(res.data.data.foward_number);            
            setLineNumber(res.data.data.foward_number);
            setToggleBtnOn(false);
            
          }).catch(error => {
            console.log("line 조회실패");
            console.log(error);
          })
        }          
      } else {
        // console.log(result.data);
        return;
      }           
    };

    fetchData();
    //if (!popOpen) {
    //  fetchData();
    //}
  }, []);

  const iHeight = (document.body.clientHeight / 2) - 80;
  const iWidth = (document.body.clientWidth / 2) -  232 / 2 + document.body.scrollLeft;
      
  const callFowarding = async () => {
    //axios.post(`/centrexApi/setforward?id=` + login_id + `&pass=` + pass + `&destnumber=` + lineNumber)

    axios({
          method: 'GET',          
          url: `/mgr/centrix/forward/${line_idx}`,
          params: {              
              mode: 'Y', //착신전환 ON
              destnumber: lineNumber
          },
          headers: {
              'Authorization': 'Bearer ' + access_token,
              'Content-Type' : 'application/json' 
          },
          //data: params
    }).then(res => {
      console.log("callForwarding")
      if (res.data.res_code === 200 || res.data.res_code === "200") {
        console.log("착신설정 성공 : " + lineNumber);
        console.log("callForwarding")
        setPopupOpen(false)
        setCallForwardon(true)
        setTimeout(function(){setCallForwardon(false)}, 1000)
      } else {
        setForwardErrorPopup(true);
        console.log("착신설정 실패 : " + lineNumber);
        setToggleBtnOn(false);
      }      
    }).catch(e => {
      setForwardErrorPopup(true);      
      console.log("에러 : " + e);
      setToggleBtnOn(false);
        
    })
  }

  const stopForwarding = async () => {        
    //axios.post(`/centrexApi/stopforward?id=`+login_id+`&pass=`+pass+`&destnumber=` + lineNumber)
     axios({
          method: 'GET',          
          url: `/mgr/centrix/forward/${line_idx}`,
          params: {              
              mode: 'N', //착신전환 ON
              destnumber: lineNumber
          },
          headers: {
              'Authorization': 'Bearer ' + access_token,
              'Content-Type' : 'application/json' 
          },
          //data: params
      }).then(res => {        
        if (res.data.res_code === 200 || res.data.res_code === "200") {
           console.log("착신전환해제 성공 : " + lineNumber);
          const params = JSON.stringify({
            "mode":"user",
            "corp_idx": corp_idx,
            "line_idx": line_idx,
            "foward_number": lineNumber            
            });

          axios.put(`/mgr/line/` + line_idx, params, { headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' } } )
            .then(res => {
              console.log("DB INSERT 성공");
            }).catch(error => {
              console.log("DB INSERT 실패");
              console.log(error);
            })
            setCallForwardoff(true)
            setTimeout(function(){setCallForwardoff(false)}, 1000)
        } else {
          alert("착신전환 에러발생");
        }
      }).catch(error => {
        callFowarding();
        console.log(error);
        console.log("착신전환해제 실패 : " + lineNumber);
        //console.log(error);
      })        
  }
  
  const fowardNumberSubmit = () => {

    //const regex = /^(00)*$/;
    
    const regex1 = /^00/; // 00 으로 시작하는거
    const regex2 = /^15/; // 00 으로 시작하는거
    const regex3 = /^16/; // 00 으로 시작하는거
    const regex4 = /^18/; // 00 으로 시작하는거
    const regex5 = /^050/; // 00 으로 시작하는거
    const regex6 = /^080/; // 00 으로 시작하는거
   
    //console.log(regex1.test(lineNumber))
    //console.log(regex2.test(lineNumber))
    //console.log(regex3.test(lineNumber))
    //console.log(regex4.test(lineNumber))
    //console.log(regex5.test(lineNumber))
    //console.log(regex6.test(lineNumber))

    //console.log(regex.test.test(lineNumber))    

    if (lineNumber.length < 1) {
      alert('번호를 입력해주세요.');
      return;
    } else {
      
      if (
        lineNumber === "112" || lineNumber === "114" || lineNumber === "119"
        || regex1.test(lineNumber) === true
        || regex2.test(lineNumber) === true
        || regex3.test(lineNumber) === true
        || regex4.test(lineNumber) === true
        || regex5.test(lineNumber) === true
        || regex6.test(lineNumber) === true
      ) { 
        alert('설정 불가능한 번호입니다.');
        return;
      }
      

      const params = JSON.stringify({
          "mode":"user",
          "corp_idx": corp_idx,
          "line_idx": line_idx,
          "foward_number": lineNumber            
      });
      
      axios.put(`/mgr/line/` + line_idx, params, { headers: { 'Authorization': 'Bearer ' + access_token, 'Content-Type': 'application/json' } } )
        .then(res => {

          console.log(res);

          if (res.data.res_code === 200 || res.data.res_code === "200") {

              //기존 착신번호랑 새로 등록한 착신번호랑 다를경우
              if (fowardData != lineNumber) {       
                setToggleBtnOn(false);
              }
            
            setFowardData(lineNumber);
            setForwardCompletePopup(true);
          } else { 
            setForwardErrorPopup(true);
          }        
          //setLineNumber(lineNumber);      
        }).catch(error => {      
        setForwardErrorPopup(true);      
        console.log(error);
      })

      setPopupOpen(false);
    }

  }

  const [tempNum, setTempNum] = useState('');
  const [updateFwNum, setUpdateFwNum] = useState(false);
  const inputref = useRef();

  useEffect(() => {
    setTempNum(lineNumber);
  }, [lineNumber])

  useEffect(() => {
    if(updateFwNum === true) {
      fowardNumberSubmit();
      setUpdateFwNum(false);
    }
  }, [updateFwNum])

  const resetFowardNum = () => {
    setTempNum('');
    inputref.current.focus();
  }


  const completeSubmit = () => {

    const regex1 = /^00/; // 00 으로 시작하는거
    const regex2 = /^15/; // 00 으로 시작하는거
    const regex3 = /^16/; // 00 으로 시작하는거
    const regex4 = /^18/; // 00 으로 시작하는거
    const regex5 = /^050/; // 00 으로 시작하는거
    const regex6 = /^080/; // 00 으로 시작하는거 

    if (tempNum.length < 1) {
      alert('번호를 입력해주세요.');
      return;
    } else {
      if (
        tempNum === "112" || tempNum === "114" || tempNum === "119"
        || regex1.test(tempNum) === true
        || regex2.test(tempNum) === true
        || regex3.test(tempNum) === true
        || regex4.test(tempNum) === true
        || regex5.test(tempNum) === true
        || regex6.test(tempNum) === true
      ) { 
        alert('설정 불가능한 번호입니다.');
        return;
      }
      setLineNumber(tempNum);
      setUpdateFwNum(true);
    }
  }


  //  const Popup = () => {
  //     return (
  //         <div className="popup" style={setPopupOpen ? { display:'block' } : { display:'none'}}>
  //           <div className="popup_inner">
            
  //               <div className="popup_inner_div text-center" >
            
  //               <p className="mb-0" style={{  }}>착신번호 설정</p>            
  //               <p className="mb-0" >착신 걸어놓을 번호를 입력해주세요!</p>                
  //                 <div className="d-inline-block"  >
  //                   <Form.Control type="text" className="text-center"                        
  //                       maxLength={11}
  //                       required 
  //                       autoFocus 
  //                       value={lineNumber || ''}
  //                       onChange={(e) => setLineNumber(e.target.value)}
  //               />                
  //                   <p className="mb-0" >- 없이 입력해주세요.</p>
  //                 </div>
  //             </div>            
  //               <div className="popup_inner_bottom d-flex justify-content-between text-center">
  //                   <div className="w-100" onClick={() => { setPopupOpen(false); }}>
  //                       취소
  //                   </div>
  //                   <div className="w-100" onClick={() => {fowardNumberSubmit()}}>
  //                       설정
  //                   </div>
  //               </div>     
  //           </div>                
  //     </div>
  //     )
  // }

  
  const ExampleToast = ({ children }) => {
    const [show, toggleShow] = useState(false);

      return (
        <>
          {!show && <Button onClick={() => toggleShow(true)}>계정정보</Button>}
          <Toast show={show} onClose={() => toggleShow(false)}>
            <Toast.Header>
              <strong className="mr-auto">계정정보</strong>
            </Toast.Header>
            <Toast.Body>{children}</Toast.Body>
          </Toast>
        </>
      );
  };


  return (
    <div className="wrapper">
      <div style={{ padding: '30px 24px 0 24px' }}>
        <p className="title-top mb-0" style={{ paddingBottom:'12px' }}>클라우드레코드</p>
        <p className="title-bottom mb-0">로그인되었습니다.</p>
        {data ? (
              <Card className="content-card" >
                <Card.Body className="content-cardBody" >
                  <div className="text-end">
                  {line_auth === "Y" ? 
                  <button className="btn_auth" style={{ color:'#cf1a70' , width:'72px'}}>관리자</button>
                  : <button className="btn_auth">일반 사용자</button>}
                
                  </div>
                  <table className="mainTable">
                  <tbody>
                    <tr>
                        <td>회사명</td>
                        <td>{login_name}</td>
                      </tr>
                      <tr>
                        <td>대표번호</td>
                        <td>{Func.replaceNumber(corp_number)}</td>
                      </tr>
                      <tr>
                        <td>아이디</td>
                        <td>{Func.replaceNumber(login_id)} </td>
                      </tr>
                      {/* <tr>
                            <td>사업자번호</td>
                            <td>{business_number} </td>
                      </tr> */}
                      <tr>
                        <td>내선번호</td>
                        <td>{data.exten}</td>
                      </tr>
                      {/* <tr>
                        <td>070번호</td>
                        <td>{data.number070}</td>
                      </tr> */}
                      <tr>
                        <td>착신설정</td>
                        <td>
                            <button className={toggleBtnOn ? 'on' : 'off'} onClick={toggleBtn} style={fowardBtn} >
                              <span className="pin" />
                              {toggleBtnOn ? 
                              <span className="button-on" style={{ color:'#FFFFFF' , fontSize:'14px'}}>ON</span>
                                : 
                              <span className="button-off" style={{ color:'#383838', fontSize:'14px' }}>OFF</span>
                              }        
                            </button>
                        </td>
                      </tr>
                      <tr>
                      <td>착신번호</td>
                        {/* 
                        <td style={{ color: '#c7c7c7', textDecoration: 'underline', cursor: 'pointer' , ...(foward_yn === "Y" ? { color: 'blue' } : {}) }}  onClick={() => setPopupOpen(true)}>
                        */}
                        <td className="forward_number" style={{ color: '#c7c7c7', textDecoration: 'underline', cursor: 'pointer' , ...(toggleBtnOn ? { color: 'rgb(207, 26, 112)' } : {}) }}  onClick={() => setPopupOpen(true)}>
                        {
                        foward_yn === "Y" ? <>{Func.replaceNumber(fowardData)}</>
                          : fowardData !== "" ? <>{Func.replaceNumber(fowardData)}</> : <>번호를 설정해주세요</>
                        }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Card.Body>
              </Card>          
        ) : <Card className="text-center p-2">데이터 로딩중 ...</Card>}
        
      </div>            

       {popOpen === true ? (
            <FowardPopup type={"info"} inputref={inputref} open={popOpen} close={setPopupOpen} tempNum={tempNum} tempNumFunc={setTempNum} lineNumber={lineNumber} lineNumberFunc={setLineNumber} fowardNumberFunc={fowardNumberSubmit} complete={completeSubmit} timeout={1000} reset={resetFowardNum} ></FowardPopup>
      ) : <>{popOpen}</>}

      {forwardCompletePopup === true ? (
            <ForwardCompletePopup type={"info"} open={forwardCompletePopup} close={setForwardCompletePopup}  fowardNumberFunc={fowardNumberSubmit} ></ForwardCompletePopup>
      ) : <>{forwardCompletePopup}</>}

      {forwardErrorPopup === true ? (
            <ForwardErrorPopup type={"info"} open={forwardErrorPopup} close={setForwardErrorPopup} lineNumber={lineNumber} lineNumberFunc={setLineNumber} fowardNumberFunc={fowardNumberSubmit} timeout={1000} ></ForwardErrorPopup>
      ) : <>{forwardErrorPopup}</>}
      
      {callForwardon === true ? (
          <CallFwOnPopup open={callForwardon} msg="착신전환 설정하였습니다." width={iWidth} top={iHeight} />
      )
      :
          <>{callForwardon}</>
      }

      {callForwardoff === true ? (
          <CallFwOffPopup open={callForwardoff} msg="착신전환 해제하였습니다." width={iWidth} top={iHeight} />
      )
      :
          <>{callForwardoff}</>
      }
      
        {/* <ExampleToast>
          <InputGroup>
            <Form.Group>              
              <Form.Control placeholder="01000000000" value={value} maxLength="11" onChange={onChange} />
              <Button variant="outline-secondary" onClick={callFowarding}>착신전환</Button>
              <Button variant="outline-secondary" onClick={stopForwarding}>착신전환해제</Button>
            </Form.Group>
          </InputGroup>
      </ExampleToast> */}
      
    </div>
  )
}

export default Home;