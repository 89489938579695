import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { setCookie, getCookie } from '../ReactCookie';

import { isBrowser, isAndroid, browserName, deviceType } from "react-device-detect";
import { Redirect, Link, RouteComponentProps, useHistory } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { AiOutlineClose, AiFillCloseCircle, AiFillEyeInvisible, AiFillEye, AiOutlineRight } from "react-icons/ai";
import { ErrPopup, ErrPopup2, FindPopup, QnaPopup, JoinPopup, PrivacyPopup } from './login/Popup';
import '../css/login.css';
import '../css/popup.css';

const Login = ({ authenticated, location, ...props }) => {    

    //console.log(location);
    //console.log("--------------")
    //console.log(props);

    const user_agent = browserName;

    const [acc_token, setAcc_token] = useState("");
    const [corp_number, setCorp_number] = useState("");
    const [line_number, setLine_number] = useState("");
    const [line_pwd, setLine_pwd] = useState("");
    const [line_idx, setLine_idx] = useState("");

    const [remember_yn, setRemember_yn] = useState(false);
    const [all_yn, setAll_yn] = useState("N");
    const [privacy_yn, setPrivacy_yn] = useState("N");
    const [privacy_yn2, setPrivacy_yn2] = useState("N");
    const [advertise_yn, setAdvertise_yn] = useState("N");
    const [pwd_type, setPwd_type] = useState({ type: "password" , visible: false });
    const [number_error, setNumber_error] = useState(false);
    const [id_error, setId_error] = useState(false);
    const [pwd_error, setPwd_error] = useState(false);

    const [error_open, setErr_open] = useState(false);
    const [error_open2, setErr_open2] = useState(false);
    const [find_open, setFind_open] = useState(false);
    const [qna_open, setQna_open] = useState(false);
    const [join_open, setJoin_open] = useState(false);
    const [privacy_open, setPv_open] = useState(false);
    const [privacy_info1, setPv_info1] = useState(false);
    const [privacy_info2, setPv_info2] = useState(false);
    const [privacy_info3, setPv_info3] = useState(false);
    const [privacy_array, setPv_array] = useState({ all_yn: all_yn, privacy_yn: privacy_yn, privacy_yn2: privacy_yn2, advertise_yn: advertise_yn, privacy_info1: privacy_info1, privacy_info2: privacy_info2, privacy_info3: privacy_info3});

    const ck_corp_number     = getCookie("corp_number");
    const ck_line_number     = getCookie("line_number");
    const ck_remember_yn    =  getCookie("remember_yn");

    const iHeight = (document.body.clientHeight / 2) - 148;
    const iWidth = (document.body.clientWidth / 2) -  232 / 2 + document.body.scrollLeft;
    
    const history = useHistory();  
   
    // Login Component 최초 렌더링 시에만 useEffect 작동
    useEffect(() => {

        //const state = {
        //    corp_number:'',
        //    line_number:''
        //}
        //history.replace({ ...history.location, state });     
        
        try {
            if (location.state.corp_number !== undefined) {

                ck_remember_yn === "true" ? setRemember_yn(true) : setRemember_yn(false);

                setCorp_number(location.state.corp_number);
                setLine_number(location.state.line_number);                

            } else {
                //console.log('여기2 쿠키체크 : ' + ck_remember_yn);

                if (ck_remember_yn==="true") {
                    setCorp_number(ck_corp_number);
                    setLine_number(ck_line_number);
                    setRemember_yn(true);
                }
                else {
                    setCorp_number("");
                    setLine_number("");
                    setRemember_yn(false);
                }
            }
        } catch (e) {
            if (ck_remember_yn==="true") {
                setCorp_number(ck_corp_number);
                setLine_number(ck_line_number);
                setRemember_yn(true);
            }
            else {
                setCorp_number("");
                setLine_number("");
                setRemember_yn(false);
            }
        }        
    }, []); 


    // 로그인 시도 Null값 오류 발생 Input 감지
    useEffect(() => {
        let input = document.getElementsByClassName("input_error");
        for(let i=0; i<input.length; i++) {
            //console.log(input[i]);
            input[i].classList.remove("input_error");
        }
        
        if (number_error===true) {
            document.getElementById("corp_number").classList.add("input_error");
        }
        if (id_error===true) {
            document.getElementById("line_number").classList.add("input_error");
        } 
        if (pwd_error===true) {
            document.getElementById("line_pwd").classList.add("input_error");
        }
    }, [number_error,id_error,pwd_error,privacy_open]);

    // 로그인 시도 Null값 오류 이후 값 변경 감지
    useEffect(() => {
        if (number_error===true || id_error===true || pwd_error===true){
            if (corp_number!=="") {
                document.getElementById("corp_number").classList.remove("input_error");
            }
            if (line_number!=="") {
                document.getElementById("line_number").classList.remove("input_error");
            } 
            if (line_pwd!=="") {
                document.getElementById("line_pwd").classList.remove("input_error");
            }
        }
    }, [corp_number,line_number,line_pwd]);

    // 로그인 상태 유지 체크 변경 감지
    //useEffect(() => {
    //    if (ck_remember_yn==="true") {
    //        setCorp_number(ck_corp_number);
    //        setLine_number(ck_line_number);
    //    }
    //}, [ck_remember_yn,ck_corp_number,ck_line_number]);

    // 개인정보활용 동의 팝업 변수값 변경 감지
    useEffect(() => {
        if(privacy_open === true){
            setPv_array({ all_yn: all_yn, privacy_yn: privacy_yn, privacy_yn2: privacy_yn2, advertise_yn: advertise_yn, privacy_info1: privacy_info1, privacy_info2: privacy_info2, privacy_info3: privacy_info3});
        }
    }, [all_yn,privacy_yn,privacy_yn2,advertise_yn,privacy_info1,privacy_info2,privacy_info3]);

    // 체크박스 onChange 함수
    const changeCheckbox = (type) => {
        if (type === 'rm') {            
            remember_yn === false ? setRemember_yn(true) : setRemember_yn(false);
        }
        else if (type === 'pv1') {            
            privacy_yn === "N" ? setPrivacy_yn("Y") : setPrivacy_yn("N");            
        }
        else if (type === 'pv2') {            
            privacy_yn2 === "N" ? setPrivacy_yn2("Y") : setPrivacy_yn2("N");            
        }
        else if (type === 'av'){
            advertise_yn === "N" ? setAdvertise_yn("Y") : setAdvertise_yn("N");
        }
        else {
            if (all_yn === "N") {
                setAll_yn("Y");
                setPrivacy_yn("Y");
                setPrivacy_yn2("Y");
                setAdvertise_yn("Y");
            }
            else {
                setAll_yn("N");
                setPrivacy_yn("N");
                setPrivacy_yn2("N");
                setAdvertise_yn("N");
            }
        }
    }


    // 개인정보활용 동의 & 광고 수신 동의 onClick 함수
    const openInfo = (num) => {
        setPv_info1(false);
        setPv_info2(false);
        setPv_info3(false);

        if ( num === 1 ) {
            privacy_info1 === true ? setPv_info1(false) : setPv_info1(true);
        }
        else if ( num === 2) {
            privacy_info2 === true ? setPv_info2(false) : setPv_info2(true);
        }
        else { 
            privacy_info3 === true ? setPv_info3(false) : setPv_info3(true);
        }
    }
    // 대표번호, 아이디 set Only Number onChange 함수
    const handleKeyType = (e, type) => {
        if (type==="num") {
            setCorp_number(e.target.value.replace(/[^0-9]/g, ""));
        }
        else {
            setLine_number(e.target.value.replace(/[^0-9]/g, ""));
        }
    }
    // 비밀번호 Show onClick 함수
    const handlePasswordType = () => {
        pwd_type.visible ? setPwd_type({ type: "password", visible: false }) : setPwd_type({ type: "text", visible: true });
    }

    const handleKeyPress = (e) => {
        e.key === "Enter" ? submit() : <></>;
    }
    
    const submit = () => {
        if ( corp_number === "" || line_number === "" || line_pwd === "" ) {
            corp_number === "" ? setNumber_error(true) : setNumber_error(false);
            line_number === "" ? setId_error(true) : setId_error(false);
            line_pwd === "" ? setPwd_error(true) : setPwd_error(false);
            return;
        }else {
            try {
                const user = { corp_number, line_number, line_pwd, user_agent };
                
                // ( line_number === "" || line_pwd === "" ) ? setErr_open(true) : <></>;
                
                axios.post('/mgr/member/login', user, { headers: { 'Content-Type': 'application/json' } } ) 
                .then(res => { 
                    if(res.data.res_code===200){
                        
                        setAcc_token(res.data.auth.acc_token);
                        setLine_idx(res.data.auth.line_idx);
                        
                        setCookie('corp_number',corp_number);
                        setCookie('line_number',line_number);
                        setCookie('remember_yn', remember_yn);
                                                
                        if (res.data.auth.privacy_yn === "Y") {
                            setCookie('access_token_user',res.data.auth.acc_token);
                            props.loginFunc(res.data.auth.acc_token);
                        }
                        else {
                            setPv_open(true);
                            return;
                        }
                    }else{
                        // console.log(res.data.res_msg);
                        setErr_open(true);
                        return;
                    }
                }).catch(function (error) {
                    setErr_open(true);
                    if (error.response) {
                        console.log(error.response.data);
                        console.log(error.response.status);
                        console.log(error.response.headers);
                    }else if (error.request) {
                        console.log(error.request);
                    }else {
                        // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                        console.log('Error', error.message);
                    }
                });
            } catch (e) {
                console.log(e);
                setErr_open(true);
            }
        }
    }
    //개인정보활용 동의 확인 버튼 함수 호출
    const acceptSubmit = () => {

        let data = { line_idx, privacy_yn, advertise_yn };

        if ( privacy_yn === "N" || privacy_yn2 === "N") {
            setErr_open2(true);
            return; 
        }
        else {            
            axios.post('/mgr/member/privacy' , data , { headers: { 'Content-Type': 'application/json' } } ) 
            .then(res => { 
                if(res.data.res_code===200){
                    if (res.data.privacy_yn === "Y") {
                        setPv_open(false);
                        setErr_open(false);
                        setCookie('access_token_user',acc_token);
                        props.loginFunc(acc_token);
                    }
                    else {
                        // console.log(res.data.res_msg);
                        setPv_open(true);
                        return;
                    }
                }else{
                    // console.log(res.data.res_msg);
                    setErr_open(true);
                    return;
                }
            }).catch(function (error) {
                setErr_open(true);
                console.log(error);
            });
        }
    }

    //로그인 정보가 있으면  로 보내줌    
    const { from } = location.state || { from: { pathname: "./" } };        
    //console.log(from);
    if (authenticated) return <Redirect to={from} />;

    return (        
        <div className="container-signin">
            <div className="topBar"></div>
            {/* <div style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/img/ping_circle.png)`, backgroundRepeat: 'no-repeat' }}>asdf</div> */}
            {/* style={{ backgroundImage: `url(${process.env.REACT_APP_PUBLIC_URL}/img/ping_circle.png)`, backgroundRepeat:'no-repeat' }} */}
            <div className="form-main signin" >
                <Form className="form-signin" >
                    {/*
                    <h2 className="form-signin-heading text-left">IQ200</h2>                     
                    */}
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/클라우드레코드@2x.png`} alt="" className="form-signin-heading text-left" style={{width:'145px'}}/>
                    <Form.Group className="form-group signin"  >
                        <Form.Control type="text" id="corp_number" className="form-control input-lg signin" placeholder="대표번호"  
                            required 
                            value={corp_number || ''}
                            onChange={(e) => handleKeyType(e,'num')}
                            // style={{ color : number_error ? "#cf1a70": "black", border : number_error ? "solid 1px #cf1a70" : "solid 1px #c4c4c4" }}
                            />
                        { corp_number !== "" ? <AiFillCloseCircle className="input-signin_icon" onClick={ ()=> setCorp_number("") }></AiFillCloseCircle> : <></> }
                    </Form.Group>
                    <Form.Group className="form-group signin">
                        <Form.Control type="text" id="line_number" className="form-control input-lg signin mt-2 mb-2" placeholder="아이디" 
                            required 
                            value={line_number || ''} 
                            onChange={(e) => handleKeyType(e,'id')}
                            // style={{ color : id_error ? "#cf1a70": "black", border : id_error ? "solid 1px #cf1a70" : "solid 1px #c4c4c4" }}
                            />
                        { line_number !== "" ? <AiFillCloseCircle className="input-signin_icon" onClick={ ()=> setLine_number("") }></AiFillCloseCircle> : <></> }
                    </Form.Group>
                    <Form.Group className="form-group signin" style={{ color : pwd_error ? "#cf1a70": "black" }}>
                        <Form.Control type={pwd_type.type} id="line_pwd" className="form-control input-lg signin" placeholder="비밀번호" 
                            required 
                            value={line_pwd} 
                            onChange={(e) => setLine_pwd(e.target.value)} 
                            onKeyPress={(e)=>handleKeyPress(e)} 
                            // style={{ color : pwd_error ? "#cf1a70": "black", border : pwd_error ? "solid 1px #cf1a70" : "solid 1px #c4c4c4" }}
                            />
                        { line_pwd !== "" ? 
                            pwd_type.visible 
                                ? <AiFillEye className="input-signin_icon" onClick={handlePasswordType}></AiFillEye> 
                                : <AiFillEyeInvisible className="input-signin_icon" onClick={handlePasswordType}></AiFillEyeInvisible> 
                            : <></> }                
                    </Form.Group>
                    <div className="d-flex justify-content-between mr-auto mb-4" style={{ paddingTop:'13px' }}>
                        <div className="d-flex">
                            <Form.Control type="checkbox" id="remember_yn" className="form-check-input mt-2 form_rember" value={remember_yn} onChange={ ()=>changeCheckbox('rm') } checked={remember_yn === true ? true : false}/>
                            <Form.Label className="form-signin-label" htmlFor="remember_yn">로그인 상태 유지</Form.Label>
                        </div>
                        <div className="form-signin-qna" onClick={ () => setQna_open(true) } style={{ cursor:'pointer' }}>
                            <span>도움말</span>
                        </div>
                    </div>
                    <Button className="btn btn-md btn-block btn-lg signin text-center" type="button" onClick={submit}>로그인</Button>
                    <div className="d-flex justify-content-between text-center mb-1 service_list">
                        <div className="form-signin-p" onClick={ ()=> setFind_open(true) } style={{ cursor:'pointer' }}>
                            로그인 정보 찾기
                        </div>
                        <div className="form-signin-p" onClick={ ()=> setJoin_open(true) } style={{ cursor:'pointer' }}>
                            가입/신청문의
                        </div>
                    </div>
                    {error_open === true ? ( <ErrPopup open={error_open} top={iHeight} width={iWidth} close={setErr_open} timeout={1000} ></ErrPopup> ) : <> {error_open} </>}
                    {error_open2 === true ? ( <ErrPopup2 open={error_open2} top={iHeight} width={iWidth} close={setErr_open2} timeout={1000} ></ErrPopup2> ) : <> {error_open2} </>}
                    {find_open === true ? (<FindPopup open={find_open} top={iHeight} width={iWidth} close={setFind_open} timeout={1000} ></FindPopup> ) : <> {find_open} </>}
                    {qna_open === true ? ( <QnaPopup open={qna_open} top={iHeight} width={iWidth} close={setQna_open} timeout={1000} ></QnaPopup> ) : <> {qna_open} </>}
                    {join_open === true ? ( <JoinPopup open={join_open} top={iHeight} width={iWidth} close={setJoin_open} timeout={1000} ></JoinPopup> ) : <> {join_open} </>}
                    {privacy_open === true ? ( <PrivacyPopup open={privacy_open} top={iHeight} width={iWidth} close={setPv_open} pv_arr={privacy_array} checkboxFunc={changeCheckbox} infoFunc={openInfo} accSubmitFunc={acceptSubmit} timeout={1000} ></PrivacyPopup> ) : <> {privacy_open} </>}
                </Form>
            </div>
        </div>
    );
}
export default Login;