import Cookies  from 'universal-cookie'

const cookies = new Cookies();

export const allCookie = (name,value) =>{
    return cookies.getAll(name,value);
}

export const setCookie = (name , value) =>{
    return cookies.set(name,value, {path : "/"});
}

export const getCookie = (name) => {

    return cookies.get(name, {path : "/"});
}

export const removeCookie = ( name ) => {
    return cookies.remove(name, {path : "/"});
}

export const isValidCookie = (name) =>{
    //let acc_token = getCookie(name);    
}

export const deleteAllCookies = (type) =>{
    let cookie_split = cookies.getAll();

    for (var name in cookie_split) {
        //console.log(key, cookie_split[key]);
        if (type==="true") {
            if (name!=="remember_yn" && name!=="corp_number" && name!=="line_number") {
                removeCookie(name);
            }
        }
        else {
            removeCookie(name);
        }
    }
}

export const getToken = (token) => {    

    if (token) {
        let acc_tokenArray = token.split('.')[1];
        let payload = Buffer.from(acc_tokenArray, 'base64');
        let acc_token_res = JSON.parse(payload.toString());
        return acc_token_res;
    } else {
        return;
    }
    
}