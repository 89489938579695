import Form from 'react-bootstrap/Form';
import { AiOutlineClose, AiOutlineRight } from "react-icons/ai";
import { Link, useHistory } from 'react-router-dom';
import { FiPhoneForwarded , FiPhoneIncoming } from "react-icons/fi";
import { GrClose } from "react-icons/gr";

import { Button, Image, List, Table } from 'semantic-ui-react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faAnglesRight, faAnglesLeft, faX } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useRef } from 'react';

import * as Func from '../common/Func';




/* -------------------------- 착신전환 on / off Popup List --------------------------*/

// 착신전환 설정 On
export const CallFwOnPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 1, background:'rgba(0, 0, 0, 0.16)' }}>                
            <div className="popup_find" style={{ width:'232px', height:'80px' , top: props.top, left: props.width }}>
                <div className="mt-4 mr-auto text-center popup_custom_top">
                    <span>{props.msg}</span>
                </div>        
            </div>
        </div>
    )
}


// 착신전환 설정 Off
export const CallFwOffPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 1, background:'rgba(0, 0, 0, 0.16)' }}>                
            <div className="popup_find" style={{ width:'232px', height:'80px' , top: props.top, left: props.width }}>
                <div className="mt-4 mr-auto text-center popup_custom_top">
                    <span>{props.msg}</span>
                </div>        
            </div>
        </div>
    )
}


/* -------------------------- 로그인 Popup List --------------------------*/

// 로그인 실패 에러 팝업
export const ErrPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 2 }}>                
            <div className="popup_warning" style={{width:'232px', height:'133px' , top: props.top, left: props.width }}>
                <div className="mr-auto text-center warning popup_text ">
                    <p className="mb-0">회원님의 정보가</p>
                    <p className="mb-0">일치하지 않습니다.</p>
                    <p className="mb-1">다시 입력해주세요.</p>
                </div>                
                <div className="popup_btn warning"  onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>           
            </div>
        
        </div>
    )
}
// 개인정보활용 동의 에러 팝업
export const ErrPopup2 = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 2 }}>                
            <div className="popup_warning privacy" style={{ top: props.top, left: props.width }}>
                <div className="mt-3 mb-3 mr-auto text-center warning popup_text" style={{ height:'75px' }}>
                    <p className="mb-0">서비스를 이용하시려면</p>
                    <p className="mb-0">약관 필수사항 동의가 필요합니다.</p>
                </div>                
                <div className="popup_btn warning"  onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>                    
            </div>                
        </div>
    )
}
// 도움말 팝업
// export const QnaPopup = (props) => {
//     return (
//         <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 1 }}>
//             <div className="popup_qna">
//                 <div className="popup_custom_top">
//                     <div className="mt-4 mr-auto">
//                         <AiOutlineClose className="popup_delBtn" onClick={ ()=>props.close(false) }></AiOutlineClose>
//                     </div>
//                     <div className="mt-4 mr-auto">
//                         <h3>도움말</h3>
//                     </div>
//                 </div>
//                 <hr className="popup_hr" />
//                 <div className="popup_custom_center">
//                     <div className="mt-3 mb-3 mr-auto text-left" style={{ fontSize:'.875rem' }}>
//                         <p className="mb-2">대표번호 : 회사의 대표번호를 입력해주세요.</p>
//                         <p className="mb-2">ex) 회사 B 대표번호 : 1661-8268</p>
                        
//                         <p className="mt-4 mb-2">아이디 : 본인 자리의 전화 회선 번호를 입력해주세요.</p>
//                         <p className="mb-2">ex) 자리 회선번호 : 전화기 화면 내에 표기되는 번호</p>

//                         <p className="mt-4 mb-2">비밀번호 : IQ200 비밀번호와 동일합니다</p>                        
//                     </div>                 
//                 </div>
//                 <div className="popup_custom_bottom" onClick={ ()=>props.close(false) }>
//                     <span>확인</span>
//                 </div>
//             </div>                
//         </div>
//     )
// }

export const QnaPopup = (props) => {
    const [qnacon, setQnacon] = useState(1);

    const prevQna = () => {
        setQnacon(prev => prev-1)
    }
    const nextQna = () => {
        setQnacon(prev => prev+1)
    }

    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 1 }}>
            <div className="popup_qna">
                <div className="popup_custom_top">
                    <div className="mt-4 mr-auto">
                        <AiOutlineClose className="popup_delBtn" onClick={ ()=>props.close(false) } style={{color: '#fff', fontSize:'20px'}}></AiOutlineClose>
                    </div>
                    <div className="mt-4 mr-auto">
                        <h3>도움말</h3>
                    </div>
                </div>
                <hr className="popup_hr" />
                <div style={{padding: '1rem 22px', position:'relative', top:'40px', display: qnacon===1? '':'none'}} >
                    <Form.Group className="form-group signin mb-2" >
                        <Form.Control type="text" id="corp_number" className="form-control input-lg signin" placeholder="대표번호" style={{background: '#fff'}} disabled/>
                    </Form.Group>
                    <div className='qna_cont'>
                        <h2>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon={faCircle} />
                                <span className='fa-layers-text' style={{color:'rgba(0, 0, 0, 0.5)', fontWeight:'bold'}}>1</span>
                            </span>
                            대표번호
                        </h2>
                        <p>회사의 대표번호를 입력해주세요. <br/>ex) 회사 B 대표번호 : <span style={{color: '#CD0572', fontWeight:'bold', textShadow: '0 0 5px #fff'}}>16618268</span></p>
                    </div>
                </div>
                <div style={{padding: '1rem 22px', position:'relative', top:'80px', display: qnacon===2? '':'none'}}>
                    <Form.Group className="form-group signin mb-2">
                        <Form.Control type="text" id="line_number" className="form-control input-lg signin mt-2 mb-2" placeholder="아이디" style={{background: '#fff'}} disabled/>
                    </Form.Group>
                    <div className="qna_cont">
                        <h2>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon={faCircle} />
                                <span className='fa-layers-text' style={{color:'rgba(0, 0, 0, 0.5)', fontWeight:'bold'}}>2</span>
                            </span>
                            아이디
                        </h2>
                        <p>본인 자리의 전화 회선 번호를 입력해주세요.<br/><span style={{color: '#CD0572', fontWeight:'bold', textShadow: '0 0 5px #fff'}}>자리 회선번호</span> : 전화기 화면 내에 표기되는 번호</p>
                    </div>
                </div>
                <div style={{padding: '1rem 22px', position:'relative', top:'135px', display: qnacon===3? '':'none'}}>
                    <Form.Group className="form-group signin mb-2">
                        <Form.Control type="password" id="line_pwd" className="form-control input-lg signin" placeholder="비밀번호" style={{background: '#fff'}} disabled />              
                    </Form.Group>
                    <div className="qna_cont">
                        <h2>
                            <span className='fa-layers fa-fw'>
                                <FontAwesomeIcon icon={faCircle} />
                                <span className='fa-layers-text' style={{color:'rgba(0, 0, 0, 0.5)', fontWeight:'bold'}}>3</span>
                            </span>
                            비밀번호
                        </h2>
                        <p><span style={{color: '#CD0572', fontWeight:'bold', textShadow: '0 0 5px #fff'}}>IQ200 비밀번호</span>와 동일합니다</p>
                    </div>
                </div>
                <div>
                    {qnacon ===1 ?
                        <></>
                        : <FontAwesomeIcon icon={faAnglesLeft} size='xl' 
                        className='position-absolute top-50 translate-middle-y' 
                        style={{marginLeft:'10px', cursor:'pointer'}}
                        onClick={prevQna} />
                    }
                    {qnacon ===3 ?
                        <></>
                        : <FontAwesomeIcon icon={faAnglesRight} size='xl' 
                        className='position-absolute top-50 end-0 translate-middle-y' 
                        style={{marginRight:'10px', cursor:'pointer'}}
                        onClick={nextQna} />
                    }
                </div>
                <div className="popup_custom_bottom" onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>
            </div>                
        </div>
    )
}


// 로그인 정보 찾기 팝업
export const FindPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none", zIndex: 1 }}>                
            <div className="popup_find" style={{ top: props.top, left: props.width }}>
                <div className="mt-4 mr-auto text-center popup_custom_top">
                    <span>로그인 정보 찾기</span>
                </div>
                <div className="mt-3 popup_custom_center find_list">
                    <div>
                        <Link to={ { pathname: '/find', state: { mode : "num" } }} >
                            <span>대표번호 찾기</span>
                        </Link>
                    </div>    
                    <div className="mt-2 mb-2">
                        <Link to={ { pathname: '/find', state: { mode : "id" } }} >
                            <span>아이디 찾기</span>
                        </Link>
                    </div>    
                    <div>
                        <Link to={ { pathname: '/find', state: { mode : "pwd" } }} >
                            <span>비밀번호 찾기</span>
                        </Link>
                    </div>    
                </div>                
                <div className="mt-4 find_btn"  onClick={ ()=>props.close(false) }>
                    <span>닫기</span>
                </div>           
            </div>
        
        </div>
    )
}
// 가입/신청문의 팝업
export const JoinPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" , zIndex:2 }}>                
            <div className="popup_warning" style={{ top: props.top, left: props.width }}>
                <div className="mt-3 mb-3 mr-auto text-center warning popup_text " style={{ height:'74px' }}>
                    <p className="mb-0">가입 및 신청문의는</p>
                    <p className="mb-0">고객센터로 문의해주세요.</p>
                    <p className="mb-0">(<a href='tel:18008005'>1800-8005</a>)</p>
                </div>                
                <div className="popup_btn warning"  onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>           
            </div>
        </div>
    )
}
// 최초 로그인시 개인정보활용 동의 팝업
export const PrivacyPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open===true ? "block" : "none", zIndex: 1 }}>                
            <div className="popup_info">
                <div className="popup_custom_top">
                    <div className="mt-2 mr-auto">
                        <AiOutlineClose className="popup_delBtn" onClick={ ()=>props.close(false) }></AiOutlineClose>
                    </div>
                    <div className="mt-4 mr-auto">
                        <span style={{ fontSize:'1.25rem', fontWeight:'700' }}>IQ200</span>
                    </div>
                    <div className="mb-1 mr-auto" style={{ marginTop:'20px' }}>
                        <span style={{ fontSize:'1.5rem', fontWeight:'700' }}>환영합니다!</span>
                    </div>
                </div>
                <div className="popup_custom_center">
                    <div className="mb-3 mr-auto text-left" style={{ color:'#c4c4c4', fontSize:'.6875rem' }}>
                        <p className="mb-1">전화통합솔루션 IQ200가 제공하는</p>
                        <p className="mb-0">서비스를 이용하시기 위해 약관 동의가 필요합니다.</p>
                    </div>                
                </div>
                <div className="popup_custom_info">
                    <div className="popup_info_content">
                        <div className="d-flex mr-auto">
                            <Form.Control type="checkbox" id="all_yn" className="p-0 form-check-input input-lg mt-2 privacy_check" value={props.pv_arr.all_yn} onChange={ ()=>props.checkboxFunc('all') } checked={props.pv_arr.all_yn === "Y" ? true : false} />
                            <Form.Label className="form-signin-label mb-0" onClick={ ()=>props.checkboxFunc('all') }>약관 전체동의</Form.Label>
                        </div>

                        <div className="d-flex mr-auto justify-content-between">
                            <div className="d-flex mr-auto">
                                <Form.Control type="checkbox" id="privacy_yn" className="p-0 form-check-input input-lg mt-2 privacy_check" value={props.pv_arr.privacy_yn} required onChange={ ()=>props.checkboxFunc('pv1') } checked={props.pv_arr.privacy_yn === "Y" ? true : false} />
                                <Form.Label className="form-signin-label mb-0" onClick={ ()=>props.infoFunc(1) }>서비스 이용약관 동의 (필수)</Form.Label>
                            </div>
                            <div onClick={ ()=>props.infoFunc(1) }>
                                <AiOutlineRight className="form-signin-icon" ></AiOutlineRight>
                            </div>
                        </div>
                        <div className="info_sub_content" style ={{ display: props.pv_arr.privacy_info1 ? "block" : "none" }}>
                            <p className="mb-2">제 1조 목적</p>
                            <p className="mb-0">1. (주)비플 (이하 "회사"라 한다) 이 제공하는 IQ200 전화메모관리 공개버전 프로그램 (이하 "소프트웨어"라 한다)을 설치하여 사용하는 사용자(이하 '사용자'라 한다)의 "소프트웨어" 사용에 관하여 필요로 하는 전반적인 내용에 대하여 규정하는 것을 목적으로 한다.</p>
                            <p className="mb-2">제 2조 저작권 및 사용의 범위</p>
                            <p className="mb-0">1. 본 “소프트웨어”의 소유권 및 저작권은 “회사”에게 있다.</p>
                            <p className="mb-0">2. “소프트웨어”와 부속 이미지 및 소프트웨어에 포함된 모든 권한은 “회사”에 있다.</p>
                            <p className="mb-0">3. “사용자”는 “소프트웨어”와 부속이미지 등을 도용 할 수 없다.</p>
                            <p className="mb-0">4. 본 “소프트웨어”의 저작권은 대한민국 및 일본, 미국의 저작권에 관한 법률, 국제법, 국제조약, 국제적인 저작권협정에 의해 보호를 받는다.</p>
                            <p className="mb-0">5. 본 약관에서 정의 하는 사용의 허락은 “소프트웨어”의 소유권 및 저작권을 가지는것은 아니다.</p>
                            <p className="mb-2">제 3조 소프트웨어에 대한 사용의 허락</p>
                            <p className="mb-0">1. 소프트웨어를 네트워크나 인터넷에 연결된 컴퓨터(이하 “서버”라 한다)에 설치하여 사용할 수 있다.</p>
                            <p className="mb-0">2. 소프트웨어는 사용상의 편의를 위해 사용자의 컴퓨터에 자료를 복사하여 보관할 수 있다.</p>
                            <p className="mb-0">3. “사용자”는 “회사”가 정한 본 약관에 동의 해야만 “소프트웨어”를 “서버”에 설치할 수 있다.</p>
                            <p className="mb-0">4. 소프트웨어의 어떠한 부분도 변형하여 사용자의 “서버”에서 운영할 수 없다.</p>
                        </div>

                        <div className="d-flex mr-auto justify-content-between">
                            <div className="d-flex mr-auto">
                                <Form.Control type="checkbox" id="privacy_yn2" className="p-0 form-check-input input-lg mt-2 privacy_check" value={props.pv_arr.privacy_yn2} required onChange={ ()=>props.checkboxFunc('pv2') } checked={props.pv_arr.privacy_yn2 === "Y" ? true : false} />
                                <Form.Label className="form-signin-label mb-0" onClick={ ()=>props.infoFunc(2) }>개인정보 수집 및 이용동의 (필수)</Form.Label>
                            </div>
                        
                            <div onClick={ ()=>props.infoFunc(2) }>
                                <AiOutlineRight className="form-signin-icon" ></AiOutlineRight>
                            </div>
                        </div>
                        <div className="info_sub_content" style ={{ display: props.pv_arr.privacy_info2 ? "block" : "none" }}>
                            <p className="mb-2">개인정보 수집 및 이용 동의</p>
                            <p className="mb-0">1. 목적 : IQ200을 통한 전화 상태, 통화내역, 녹취 확인 및 착신번호 설정</p>
                            <p className="mb-0">2. 항목 : 회사명, 회사대표번호, 아이디(회선번호), 내선번호, 비밀번호, 착신번호, 통화내역, 녹취</p>
                            <p className="mb-0">3. 보유기간 : 계약 해지 시까지 보유</p>
                        </div>

                        <div className="d-flex mr-auto justify-content-between">
                            <div className="d-flex mr-auto">
                                <Form.Control type="checkbox" id="advertise_yn" className="p-0 form-check-input input-lg mt-2 privacy_check" value={props.pv_arr.advertise_yn} onChange={ ()=>props.checkboxFunc('av') } checked={props.pv_arr.advertise_yn === "Y" ? true : false} />
                                <Form.Label className="form-signin-label mb-0" onClick={ ()=>props.infoFunc(3) }>광고성 정보 수신동의 (선택)</Form.Label>
                            </div>
                            <div onClick={ ()=>props.infoFunc(3) }>
                                <AiOutlineRight className="form-signin-icon" ></AiOutlineRight>
                            </div>
                        </div>
                        <div className="info_sub_content" style ={{ display: props.pv_arr.privacy_info3 ? "block" : "none" }}>
                            <p className="mb-2">광고성 정보 수신동의</p>
                            <p className="mb-2">등록하신 대표번호, 아이디(회선번호)로 할인정보, 새로운 기능 안내 및 이벤트 소식 등을 받으실 수 있습니다.</p>
                            <p className="mb-0">1. 항목 : IQ200 알림 동의</p>
                            <p className="mb-0">2. 이용목적 : 할인정보, 새로운 기능 및 이벤트 소식 안내</p>
                            <p className="mb-0">3. 보유기간 : 계약 해지 시까지 보유</p>
                        </div>
                    </div>
                    <div className="popup_info_btn" onClick={ ()=>props.accSubmitFunc()}>
                        <span>확인</span>
                    </div>
                </div>            
            </div>                
        </div>
    )
}

/* ----------------------------------------------------------------------------*/

/* -------------------------- 회원정보찾기 Popup List --------------------------*/

// 로그인 정보 찾기 Input Null 체크 팝업
export const NullPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" , zIndex:9 }}>
            <div className="popup_warning" style={{ top: props.top, left: props.width }}>
                <div className="mt-3 mr-auto text-center warning popup_text" style={{ height:'91px' }}>
                    <p className="mb-0">정확한 정보 검색을 위해</p>
                    <p className="mb-0">최소 두 가지 이상 입력해주세요.</p>
                </div>                
                <div className="popup_btn warning"  onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>           
            </div>
        </div>
    )
}
// 비밀번호 찾기 팝업
export const PwdPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" , zIndex:9 }}>                
            <div className="popup_warning" style={{ top: props.top, left: props.width }}>
                <div className="mt-3 mb-3 mr-auto text-center warning popup_text" style={{ height:'75px' }}>
                    <p className="mb-0">비밀번호 초기화는</p>
                    <p className="mb-0">고객센터로 문의해주세요.</p>
                    <p className="mb-0">(1661-8290)</p>
                </div>                
                <div className="popup_btn warning"  onClick={ ()=>props.close(false) }>
                    <span>확인</span>
                </div>           
            </div>
        </div>
    )
}



// 로그인 정보 찾기 결과 팝업
export const ResultPopup = (props) => {

    const history = useHistory();

    const selectedSearch = (item) => {  
        
        // console.log(item); 

        let uri  = '/login/';
        //ev.preventDefault();
        history.push(
            {
                pathname: uri,
                state: {
                    test : "zzzz",
                    corp_name: item.corp_name,
                    line_number: item.line_number,
                    corp_number: item.corp_number,                    
                }

            }
        );
    }


    let num_list = "";
    let id_list = "";
    let err_text = (
        <Table.Row >
            <Table.Cell colSpan={3} style={{lineHeight: '20px'}}>검색이 되지 않는 경우 <br/> <a href="tel:1661-8290">1661-8290</a> 고객센터로 문의 바랍니다.</Table.Cell>            
        </Table.Row>        
    );

    if (props.result.length!==0) {
        num_list = 
            props.result.map((item,index) => (
                <Table.Row onClick={() => selectedSearch(item) } key={index}>
                    <Table.Cell>{item.corp_name}</Table.Cell>
                    <Table.Cell>{item.line_number}</Table.Cell>
                    <Table.Cell>{item.corp_number}</Table.Cell>
                </Table.Row>
            ));
        id_list = 
            props.result.map((item,index) => (
                <Table.Row onClick={() => selectedSearch(item) } key={index}>
                    <Table.Cell>{item.corp_name}</Table.Cell>
                    <Table.Cell>{item.line_number}</Table.Cell>
                    <Table.Cell>{item.corp_number}</Table.Cell>
                </Table.Row>                
                // <div className="d-flex justify-content-between" style={{ maxHeight: props.bottom-250 }} key={item.index}>
                //     <div>{item.line_number}</div> 
                //     <div>{item.local_number}</div>
                // </div>
            ));
    } else {
        num_list = err_text;
        id_list = err_text;
    }
    return (
        <div className="popup">
                        
            {/* style={{ height: props.bottom , zIndex:2 }} */}
            <div className="popup_qna" style={{ backgroundColor:'#FFFFFF' }} >
                <div className="popup_custom_top">
                    <div className="mt-4 mr-auto">
                        <AiOutlineClose className="popup_delBtn" onClick={ ()=>props.close(false) }></AiOutlineClose>
                    </div>
                    <div className="d-flex justify-content-between mt-4 mr-auto">
                        <div className="mt-3 mr-auto text-left find_title">
                            <span>{props.title} 찾기</span>
                        </div> 
                        <div className="mt-4 mr-auto text-left find_count">
                           총 { props.result ? props.result.length : 0 }건
                        </div>      
                    </div>  
                </div>
                <div className="popup_custom_center">     
                    <hr className="popup_hr" /> 
                    
                    <Table attached textAlign='center'>
                            {props.mode === "num" ? 
                                <Table.Header>                                
                                    <Table.HeaderCell>회사명</Table.HeaderCell>
                                    <Table.HeaderCell>아이디(회선번호)</Table.HeaderCell>
                                    <Table.HeaderCell>대표번호</Table.HeaderCell>
                                </Table.Header>
                                
                                :
                                <Table.Header> 
                                    <Table.HeaderCell>회사명</Table.HeaderCell>
                                    <Table.HeaderCell>아이디(회선번호)</Table.HeaderCell>                                    
                                    <Table.HeaderCell>대표번호</Table.HeaderCell>
                                </Table.Header>
                            }
                        <Table.Body>
                        { props.mode==="num" ? num_list : props.mode==="id"  ? id_list  : <></> }
                        </Table.Body>
                    </Table>
            </div>
        </div>
    </div>
    )
}

/* ----------------------------------------------------------------------------*/

/* -------------------------- 통화내역 Popup List --------------------------*/

// 통화 상세내역 팝업
export const CallListPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>                
            <div className="popup_inner">
                <div className="d-flex justify-content-between text-end">
                    <div className="p-2">
                        {props.popupData.call_type === "in"
                            ? <><FiPhoneIncoming /> <span className="text-danger">수신</span></>
                            : <><FiPhoneForwarded /> <span className="text-primary">발신</span></>}
                    </div>
                    <button className="btn" onClick={() => { props.close(false); }}><GrClose/></button>
                </div>
                <div className="p-2">
                    <p>통화시각 {props.popupData.line_time}</p>
                    <p>수신번호 {props.popupData.peer_number}</p>
                    <p>내선번호 {props.popupData.line_number} ({props.popupData.local_number})</p>
                    <p>통화시간 {props.popupData.call_time} 초</p>
                </div>
                <div className="p-2">
                    {/* <audio useref="audio_tag" src={`http://1.212.9.203:39080/mgr/mp3/play/${props.popupData.record_idx}`} controls style={props.audioStyle}/> */}
                    <audio useref="audio_tag" src={`${process.env.REACT_APP_FILE_SERVER}/mgr/mp3/play/${props.popupData.record_idx}`} controls nodownload="true" style={props.audioStyle} />
                </div>                    
            </div>                
        </div>
    )
}

/* -------------------------------------------------------------------------*/

/* -------------------------- 메인화면 Popup List --------------------------*/

// 착신전환 팝업
export const FowardPopup = (props) => {
    useEffect(() => {
        props.tempNumFunc(props.lineNumber)
    }, [props.lineNumber])

    useEffect(() => {

    }, [])

    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>
            <div className="popup_inner" style={{ height:'326px' }}>
            <div className="text-center" style={{ height:'263px' }}>                
                <p className="mb-0" style={{ marginTop:'24px', fontSize:'1.125rem', color:'#494949', fontWeight:'700' }}>착신번호 설정</p>
                <p className="mb-0" style={{ marginTop:'10px', fontSize:'0.6875rem' }}>착신 걸어놓을 번호를 입력해주세요!</p>
                <div className="d-inline-block" style={{ width: '210px',height:'40px',marginTop:'12px', borderRadius: '5px' }} >
                    <div style={{position: 'relative'}}>
                        <Form.Control type="text"
                            style={{ textAlign:'center', height:'40px', border:'1px solid #c4c4c4'}}
                            maxLength={13}
                            required 
                            autoFocus 
                            // value={props.lineNumber}
                            value={Func.onChangeNum(props.tempNum)}
                            // onChange={e => { props.lineNumberFunc(e.target.value); }}
                            onChange={e => { props.tempNumFunc(e.target.value.replace(/\-/g, '')); }}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            ref={props.inputref}        
                        />
                        <button style={{position:'absolute', top:'12px', right:'10px', background:'none', padding:'0'}} onClick={props.reset}>
                            <FontAwesomeIcon icon={faX} style={{color: 'rgb(208, 26, 111)'}} />
                        </button>
                    </div>
                        <p className="mb-0" style={{ fontSize: '11px', color: '#c7c7c7', marginTop: '8px'}}>- 없이 입력해주세요.</p>
                        
                        
                        <p className="mb-0" style={{ fontSize: '0.6875rem', color: '#494949', marginTop: '14px', letterSpacing:'-.5px', textAlign:'left' }}>아래와 같은 번호는, 착신전환 번호로 설정할 수 없습니다.</p>
                        <p className="mb-0" style={{ fontSize: '0.6875rem', color: '#494949', marginTop: '8px',  letterSpacing:'-.5px', textAlign:'left' }}>- <span className="fc-red">112</span>, <span className="fc-red">114</span>, <span className="fc-red">119</span> 등으로 시작하는 특수번호</p>
                        <p className="mb-0" style={{ fontSize: '0.6875rem', color: '#494949', marginTop: '8px',  letterSpacing:'-.5px', textAlign:'left' }}>- <span className="fc-red">00X</span>로 시작하는 국제전화번호</p>
                        <p className="mb-0" style={{ fontSize: '0.6875rem', color: '#494949', marginTop: '8px',  letterSpacing:'-.5px', textAlign:'left' }}>- <span className="fc-red">15XX</span>, <span className="fc-red">16XX</span>로 시작하는 전국 대표번호</p>
                        <p className="mb-0" style={{ fontSize: '0.6875rem', color: '#494949', marginTop: '8px',  letterSpacing:'-.5px', textAlign:'left' }}>- <span className="fc-red">050X</span>, <span className="fc-red">080X</span>로 시작하는 개인 평생 번호</p>

                </div>
              </div>
              <div className="popup_inner_bottom d-flex justify-content-between text-center" style={{ height: '40px', lineHeight: '40px', fontWeight: '600', color: '#ffffff' }}>
                  <div className="w-100" style={{backgroundColor:'lightgray'}} onClick={() => { props.close(false); }}>
                      취소
                  </div>
                  {/* <div className="w-100" style={{ backgroundColor: '#D01A6F' }} onClick={() => {  props.fowardNumberFunc(); }}> */}
                  <div className="w-100" style={{ backgroundColor: '#D01A6F' }} onClick={() => { props.complete(); }}>
                      설정
                  </div>
              </div>     
          </div>                
    </div>
    )
}


// 착신전환 성공 팝업
export const ForwardCompletePopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>
            <div className="popup_inner_short">
                <div style={{ height:'75px' }}>
                    <p className="mb-0" style={{ paddingTop:'24px', fontSize: '1.125rem', color: '#494949', fontWeight: '700', textAlign: 'center' }}>착신번호 설정 완료</p>
                </div>
                <div className="popup_inner_bottom text-center" style={{ height: '40px', lineHeight: '40px', fontWeight: '600', color: '#ffffff' }}>
                    <div className="w-100" style={{backgroundColor:'#cf1a70'}} onClick={() => { props.close(false); }}>
                        확인
                    </div>
                </div>     
            </div>                
        </div>
    )
}



// 착신전환 실패 팝업
export const ForwardErrorPopup = (props) => {
    return (
        <div className="popup" style={{ display: props.open ? "block" : "none" }}>
            <div className="popup_inner_short">
                <div style={{ height:'75px' }}>
                    <p className="mb-0" style={{ paddingTop:'24px', fontSize: '1.125rem', color: '#494949', fontWeight: '700', textAlign: 'center' }}>착신번호 설정 실패</p>
                </div>
                <div className="popup_inner_bottom text-center" style={{ height: '40px', lineHeight: '40px', fontWeight: '600', color: '#ffffff' }}>
                    <div className="w-100" style={{backgroundColor:'#cf1a70'}} onClick={() => { props.close(false); }}>
                        확인
                    </div>
                </div>     
            </div>                
        </div>
    )
}

/* -------------------------------------------------------------------------*/