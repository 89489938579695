import React, { useState, useEffect} from 'react';
import {NavLink, useLocation, useHistory} from 'react-router-dom'; 
import '../../css/footer.css';
   
const Footer = (props) => {

    const { pathname } = useLocation();
    const history = useHistory();
    
    return (    
        <div className="footer">
            <div className="footer-img">
                <NavLink exact to="/" isActive={() => ['/'].includes(pathname)} >
                    {pathname == "/" ?
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/home_hover.png`} alt="" style={{ width : '30px'}}/>
                : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/home.png`} alt="" style={{ width : '30px'}}/>}                
                </NavLink>
            </div>
            <div className="footer-img">
                <NavLink exact to="/callList" isActive={() => ['/callList'].includes(pathname)} >
                    {pathname == "/callList" ?
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callList_hover.png`} alt="" style={{ width : '38px'}} />
                : <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/callList.png`} alt="" style={{ width : '38px'}} />}                
                </NavLink>
            </div>
            <div className="footer-img">
                <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/back.png`} alt="" onClick={history.goBack} style={{ width : '38px'}}/>
            </div>
        </div>
    )
}

export default Footer;