import React, { useState, useEffect ,useRef} from 'react';
import axios from "axios";

import { getCookie } from '../ReactCookie';
  
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import * as Func from './common/Func';
import '../css/scroll.css';

const Auth = (props) => {

  const usePrevious = (value) => {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

  const fowardBtn = {
    width: '55px',
    height: '22px',
    border: '0',
    borderRadius: '14px',
    position: 'relative',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    boxShadow: 'inset 0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    fontFamily: 'Pretendard'
  }

  let access_token = getCookie("access_token_user");
  const line_idx = props.cookie.line_idx;
  const corp_idx = props.cookie.corp_idx;    
  const line_number = props.cookie.line_number;
  const line_auth = props.cookie.line_auth;
  const line_auth_level = props.cookie.line_auth_level;
    
  const [data, setData] = useState();
  const [refreshKey, setRefreshKey] = useState(0);
  //const prevRefreshKey = usePrevious(refreshKey);
  
  //const [toggleBtnOn, setToggleBtnOn] = useState(false);
  //const [toggleBtnOn, setToggleBtnOn] = useState([false, false]);

  const toggleMenu = (line_idx, line_auth_level) => {

    let set_level;
    let set_acc;

    if (line_auth_level === 1) {
      set_level = 0;
      set_acc = '개인';
    } else {
      set_level = 1;
      set_acc = '전체';
    }

    const params = JSON.stringify({
      "mode":"auth",
      "corp_idx": corp_idx,
      "line_number": line_number,
      "line_auth_level":set_level,
      "acc_auth":set_acc,
    });    

    //회사목록들 보여주기
    axios.put(`/mgr/line/` + line_idx, params , {
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      }      
    }).then(res => {
      // console.log(res);
      setRefreshKey(refreshKey + 1)
        
    }).catch(error => {
      console.log("회선목록 조회실패");
      console.log(error);
    })
        
  }

  if (line_auth !== "Y") {
      alert("정상적인 접근이 아닙니다.");
      document.location.href = "/front";
  }
  
    
  useEffect(() => {
      axios.get(`/mgr/authlines/list`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + access_token
        },
        params: {          
          limit: 100,          
          line_idx: line_idx,
          corp_idx: corp_idx,
          searchData: search
        }
      }).then(res => {
        // console.log(res.data.data);

           if (res.data.data.length > 0) {
             setData(res.data.data);
             
          } else {
            // console.log(res.data.data);
            return;
          }
      }).catch(error => {
        console.log("회선목록 조회실패");
        console.log(error);
      })
  }, [refreshKey]);

  const [search, setSearch] = useState("");    
  const [active, setActive] = useState(false);
  const [nodata, setNodata] = useState(false);

  function handleSearch(value) {
    setSearch(value);
  }

  function searchList(searchData) {
    axios.get(`/mgr/authlines/list`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      },
      params: {          
        limit: 100,          
        line_idx: line_idx,
        corp_idx: corp_idx,
        searchData: search
      }
    }).then(res => {
      // console.log(res.data.data);

          if (res.data.data.length > 0) {
            setData(res.data.data);
            setNodata(false)
        } else {
          setNodata(true)
          // console.log(res.data.data);
          return;
        }
    }).catch(error => {
      console.log("회선목록 조회실패");
      console.log(error);
    })
  }

  useEffect(() => {
    if(search === "") setActive(false);
    else setActive(true)
  }, [search]);

  useEffect(() => {
    searchList();
  }, [search])


  const Linelist = () => {
    return (
      <div className='overheight' style={{overflowY: 'scroll', padding:'0 24px' }}>
        <ul className="list-group">
          { nodata ?
            <Card className="text-center p-2 ">일치하는 회선번호가 없습니다.</Card>
          :
            data && data.map((line, index) =>
              <li className="list-group-item d-flex justify-content-between align-items-center w-100" key={`authList_${line.line_idx}`} style={{ padding:'8px', fontSize: '12px' }}>
                <p className='d-flex align-items-center' style={{width:'75%'}}>
                  <span style={{width: '30%', wordBreak:'break-all'}}>{line.line_name} </span>
                  <span style={{paddingLeft: '5px'}}>{Func.replaceNumber(line.line_number)} ({line.local_number})</span>
                </p>             
                <button className={line.line_auth_level === 1 ? 'on' : 'off'} onClick={() => toggleMenu(line.line_idx, line.line_auth_level)} style={fowardBtn} >
                  <span className="pin" />
                  {line.line_auth_level === 1 ?
                    <span className="button-on" style={{ color: '#FFFFFF', fontSize: '14px' }}>전체</span>
                    :
                    <span className="button-off" style={{ color: '#383838', fontSize: '14px' }}>개인</span>
                  }
                </button>
              </li>
          )}
        </ul>
      </div>
    )
  }

  let vh =window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`)

  window.addEventListener('resize', () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  })


    return (
        <Container style={{ padding: '0', color: '#383838', marginTop:'10px' }}>
          {line_auth !== "Y" ? <>정상적인 접근이 아닙니다.</> :
            <div className='wrapheight'>
              <div style={{height: '80px', position: 'relative', zIndex: '2', padding:'0 24px'}}>
                <p className="mb-2 mt-4" style={{fontWeight: '600'}}>회선별 내역확인 권한</p>

                <div className="searchForm" style={{padding: '0', marginBottom: '15px'}}>
                  <Form.Control className="form-control input-search" type="text" value={search || ''} onChange={(e) => handleSearch(e.target.value)} />
                  <img src={`${process.env.REACT_APP_PUBLIC_URL}/img/search_24px.png`} className="input-search-icon" alt=""  />
                </div>
              </div>

              { active === true ?
                <Linelist />
                :
                <div className='overheight' style={{overflowY: 'scroll', padding:'0 24px' }}>
                  <ul className="list-group">
                    {data && data.map((line, index) =>
                        <li className="list-group-item d-flex justify-content-between align-items-center w-100" key={`authList_${line.line_idx}`} style={{ padding:'8px', fontSize: '12px' }}>
                          <p className='d-flex align-items-center' style={{width:'75%'}}>
                            <span style={{width: '30%'}}>{line.line_name} </span>
                            <span style={{paddingLeft: '5px'}}>{Func.replaceNumber(line.line_number)} ({line.local_number})</span>
                          </p>             
                          <button className={line.line_auth_level === 1 ? 'on' : 'off'} onClick={() => toggleMenu(line.line_idx, line.line_auth_level)} style={fowardBtn} >
                            <span className="pin" />
                            {line.line_auth_level === 1 ?
                              <span className="button-on" style={{ color: '#FFFFFF', fontSize: '14px' }}>전체</span>
                              :
                              <span className="button-off" style={{ color: '#383838', fontSize: '14px' }}>개인</span>
                            }
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              }
              {/* <div style={{height: '80px', position:'absolute', bottom: '0', background: 'red', width:'100%'}}> */}

              {/* </div> */}
            </div>
          }
        </Container>
      
    )
}

export default Auth;